import moment from "moment"
import ShareImage from "../images/refer_iphone_share.webp"
import RepeatRecycle from "../images/refer_repeat_recycle.webp"
import StepCollect from "../images/step_collect.webp"
import { Language } from "../store/reducers/utilsReducer"
import { utilPhonePlaceholder } from "./region"

export const localeContent = (language: Language) => {
  const content = {
    en: {
      header: {
        signUp: "Sign Up",
        signIn: "Sign In",
        existingCustomer: "Existing customer?",
        dashboard: "My Dashboard",
      },
      footer: {
        legal: "Company",
        vision:
          "Ensuring Environment Sustainability by Recycling Used Cooking Oil",
        copyright: `© ${moment().format(
          "YYYY"
        )} My Protech Sdn. Bhd.(1307580-A) All rights reserved.`,
      },
      language: ["English", "Malay", "Chinese"],
      home: {
        ecf:
          "We need your vote to help us to make Downtown KL drains clean by creating awareness of recycling ♲. Vote for us now: ",
        heroTitle: "Recycling Used Cooking Oil Made Easy",
        heroDescription:
          "Start selling your used cooking oil seamlessly in one app. View daily selling rates of used cooking oil, contact logistic for collection and view payment made to you, all in the Arus Oil App.",
      },
      mpobLicense: {
        title: "Licensed Operator – Arus Oil",
        desc:
          "MPOB License is required to collect/purchase used cooking oil. Used cooking oil ( Palm Oil Based ) is classified as sludge palm oil (SPO) under Malaysian Palm Oil Board Licensing Regulations 2005.​\n\nArus Oil is a licensed operator under MPOB with our license no as 619709017000. Operating with MPOB license ensures all collected used cooking oil are converted to bio-diesel and not for any illegal use that will harm the people and the environment.",
      },
      carbonEmission: {
        carbonTopTitle: {
          carbonTitleBlack: "Receive Report on Carbon Emission Saved",
          carbonDescription:
            "Carbon emissions are the greenhouse gas (GHG) emitted from the fuel consumption of vehicles. By recycling used cooking oil (UCO), you can view how much carbon emission you saved in the Arus Oil app. The recycled UCO can be converted to biodiesel which is more environmentally friendly than conventional diesel, and help to reduce up to 80% of carbon emissions into the atmosphere. ",
          carbonGetStarted: "Coming Soon",
          carbonTitle2: "Arus Oil - Complying ISCC",
          carbonDescription2:
            "Fun fact, do you know that we comply with ISCC (International Sustainability & Carbon Certification)? ISCC is an international certificate system aimed to incorporate sustainability criteria and advocate sustainable supply chains. \n\n This is essential to identify the effectiveness of biodiesel conversion by accounting for the distance of collecing from the point of origin of used cooking oil (a.k.a Your doorstep).",
        },
        carbonContent: {
          carbonTitle3: "Our Methodology In Calculating Carbon Emissions Saved",
          carbonDescription3:
            "We provide transparency and accountability for carbon emissions you saved by reporting how much used cooking oil you recycled and the distance travelled by our logistics team to your location. ",
          carbonHeader1: "Distance Travelled",
          carbonCaption1:
            "Our Arus Oil dashboard will record the distance travelled during collecting the recycled UCO and calculate the carbon emission based on the fuel consumption of the collection. ",
          carbonHeader2: "Carbon Emission Saved Based On Weight",
          carbonCaption2:
            "We enforce a policy of a minimum weight of 5kg UCO for door to door collection to ensure gross carbon emission. Besides, our dashboard algorithms optimise our collections from all nearby schedules to ensure fuel efficiency",
          carbonButtonText: "View Faqs",
        },
      },
      jmbInitiative: {
        jmbTopTitle: {
          jmbTitleBlack: "Recycling UCO Campaign with Joint Management Bodies",
          jmbDescription:
            " We collaborate with Joint Management Bodies(JMB) or Management Council(MC) to increase awareness of recycling used cooking oils among the residents via promotional campaigns. \n\n By collaborating with us to promote recycling efforts, the phenomenon of sink or pipe clogging may be reduced as residents are well informed with the correct method to dispose their used cooking oil.",
          jmbGetStarted: "Get our FREE brochure",
          jmbTitle2: "Why Encourage Recycling?",
          jmbDescription2: `Do you know that every single year, there is approximately 120 million metric tons of cooking oil are being thrown in Malaysia? The untreated used cooking oils (UCOs) in sewage often attract pests such as mice and cockroaches that eventually cause disease infection to the local community. \n\n Besides polluting our water and harming our aquatic food chain, large disposal of UCOs regularly clogs out the drainage in domestic sewage and causes water stagnation, and eventually gives rise to floods during the monsoon session in Malaysia. \n\n With Arus Oil, you can play your role in saving and promoting a healthy living environment by recycling used cooking oil and converting it to bio-diesel where it is widely used as an alternative fuel to power industrial lorries and vans. `,
        },
        jmbContent: {
          jmbTitle3: "What We Will Be Providing You",
          jmbDescription3:
            "We have experience in helping you to launch a successfully used cooking oil recycling program in different communities based in Selangor, Penang, and other states of Malaysia.  ",
          jmbHeader1: "Marketing Material",
          jmbCaption1:
            "Our team will provide marketing slides and media for your promotional campaign and conduct an introduction workshop on recycling used cooking oils to your residents.",
          jmbHeader2: "Admin Portal",
          jmbCaption2:
            "We will generate a promo code to encourage your community to recycle used cooking oils and you can check their participation in the admin portal that we specially created for you.",
        },
        jmbGetInTouch: {
          jmbTitle4: "Contact Us",
          jmbDesc:
            "For JMB partnership and inquiries, please contact us via the contact listed",
          jmbSmallHeader1: "Office",
        },
      },
      ngoInitiative: {
        ngoTopTitle: {
          heroTitleBlack: "Contribute to the Community by Recycling UCO",
          description:
            "Have your small acts of kindness make a big impact by donating your cashback from recycling used cooking oil to local social enterprises or non-profit organisations. \n\n Besides recycling your used cooking oil to be converted to biodiesel to power industrial vehicles and machines, you can also donate cash paid to you to NGOs to combat both social and environmental issues at the same time.",
          ngoGetStarted: "Get Started",
          ngoTitle2: "Why Do We Allow Donation?",
          ngoDescription2:
            "Malaysians can be categorized into three different income groups, B40, M40 & T20. While B40 groups often recycle used cooking oils to obtain certain cashback as a supplemental income to support their life, we provide options for M40 and T20 groups who choose to donate the cashback they earned from recycling for the acts of charity. \n\n By donating extra cashback, it helps to deliver awareness of tackle social issues among Malaysians, and at the same time, you can enjoy tax exemption from your donation to any government-approved charitable organisation.",
        },
        ngoFooter: {
          ngoTitle1: "Organisations We Are Working With",
          ngoCaption1:
            "We work closely with local NGOs and charity organisations to contribute to the community by combating social issues. Join us on the Donees/Donation page of the Arus Oil App to view more charity organisations.",
          ghcaTitle: "Great Heart Charity Association",
          ghcaType: "Dialysis Patients, Old Folks, Orphans, OKU, Oang Asli",
          ghcaDescription:
            "Great Heart Charity Association is a non-profit charitable organization in Malaysia. They aim to provide assistance to the needy that is both substantial and meaningful assistance. Their core value is to make charitable giving a part of everyone’s life.",
          mrcsTitle: "Malaysian Red Crescent Society",
          mrcsType: "Vulnerable Populations - Undocumented, Homeless, Migrants",
          mrcsDescription:
            "The Malaysian Red Crescent Society is a non-profit organisation dedicated to humanitarian acts and services.",
          wawTitle: "What A Waste",
          wawType: "B40 Communities",
          wawDescription:
            "What A Waste (WaW) platform is built to reduce the amount of good food that gets thrown away at food outlets everyday simply because it hasn’t been sold.",
          yckTitle: "Yayasan Chow Kit",
          yckType: "Minors in Kuala Lumpur",
          yckDescription:
            "Yayasan Chow Kit is a 24-hour crisis and drop-in centre providing meals, activities, therapy, case management, ​and educational programmes for at-risk children of Chow Kit.",
        },
      },
      privacypolicy: {
        termsandcondition: {
          title1: `Arus Oil - Privacy Policy`,
          paragraph1: `My Protech Sdn Bhd (1307580-A) (“My Protech”) warrants that we will take precautions to ensure your privacy and personal data is protected.`,
          paragraph2: `This policy details how we manage your personal information and how to contact us if you have any queries.`,
          definition: {
            title: `Definitions`,
            paragraph1: `Undefined terms in this privacy policy have the same definition as in our `,
            reference1: `terms of service `,
            terms: `("Terms")`,
          },
          title2: `Personal Information that we collect`,
          title3: `Information needed for account creation on the Arus Oil App `,
          informationCreation: {
            desc:
              "We collect your personal information when you create an account on the Arus Oil App (“App”). Without it, we may not be able to provide you with all services requested. The information includes: ",
            bold1: `Contact information, account, personal information. `,
            point1:
              "Such as your first name, last name, phone number, postal address, email address, date of birth, and profession. ",
            bold2: `Identity verification and payment information. `,
            point2:
              "Such as your IC number or other verification, bank account or payment account information. ",
            bold3: `Additional personal information. `,
            point3:
              "Such as gender, preferred language(s), city, and personal description. Some of this information as indicated in your account settings is part of your public profile page and will be publicly visible. ",
            bold4: `Other information. `,
            point4:
              "Such as when you fill in a form, add information to your account, respond to surveys, respond to community forums, participate in promotions, communicate with our customer care team and other Users, or share your experience with us. This may include health or travel information. ",
          },
          title4: `Information mandatorily collected when booking a collection`,
          informationMandatorily: {
            desc:
              "We automatically collect personal information when you book a collection pick up. This information may include: ",
            bold1: `Geo-location information. `,
            point1:
              "Such as precise or approximate location determined from your IP address or mobile device’s GPS depending on your device settings. We may also collect this information when you’re not using the App if you enable this through your settings or device permissions. ",
            bold2: `Usage information. `,
            point2:
              "Such as the pages or content you view, bookings you have made, and other actions on the Arus Oil App. ",
            bold3: `Log data and device information. `,
            point3:
              "Such as details about how you’ve used the App (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the App. We may collect this information even if you haven’t created an Arus Oil account or logged in. ",
            bold4: `Cookies and similar technologies. `,
            bold5: `Payment transaction information. `,
            point5:
              "Such as payment instrument used, date and time, payment amount, payment instrument expiration date, billing postcode, email address, IBAN information, your address and other related transaction details. ",
            bold6: `Personal declaration. `,
            point6:
              "Such as health declaration and financial declaration forms. ",
          },
          title5: `Personal information collected from third parties `,
          informationCollected: {
            desc: `We collect personal information from other sources, such as: `,
            bold1: `Third-party services. `,
            point1: `If you link, connect, or login to the  App with a third party service (e.g. Google, Facebook, Instagram), you direct the service to send us information such as your registration, friends list, and profile information as controlled by that service or as authorized by you via your privacy settings with that service. `,
            bold2: `Background information. `,
            point2: `To the extent permitted by applicable laws and with your consent where required, we may obtain the local version of police, background or registered sex offender checks. We may use your information, including your full name and date of birth, to obtain such reports. `,
            bold3: `Other participating organisations, bodies and agencies. `,
            point3: `If you are invited to the Arus Oil App by other organisations, organisations, bodies or agencies, you may have submitted personal information about yourself such as your email address or other contact information. `,
            bold4: `Other sources. `,
            point4: `To the extent permitted by applicable laws, we may receive additional information about you, such as references, demographic data or information to help detect fraud and safety issues from third party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results or fraud warnings from identity verification service providers for use in our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the  App, or about your experiences and interactions from our partners. We may receive health information, including but not limited to health information related to contagious diseases. `,
          },
          title6: `How we use the information collected `,
          provideService: {
            boldDesc: `Provide, improve, and develop services on the Arus Oil App. `,
            desc: `We use personal information to: `,
            point1: `Enable you to access the App; `,
            point2: `Enable you to communicate with other users and admins; `,
            point3: `Perform analytics, debug and conduct research; `,
            point4: `Provide collection services at your location; `,
            point5: `Provide coordination and organisation of events near you; `,
            point6: `Send you messages, updates, security alerts, and account notifications; `,
            point7: `Personalize and customize your experience based on your interactions with the App, your search and booking history, your profile information and preferences, and other content you submit. `,
          },
          maintainTrust: {
            boldDesc: `Create and maintain a trusted and safe environment. `,
            desc: `We use personal information to: `,
            point1: `Detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity; `,
            point2: `Study and combat discrimination consistent with our non-discrimination policy; `,
            point3: `Conduct security investigations and risk assessments; `,
            point4: `Verify or authenticate information provided by you; `,
            point5: `Conduct checks against databases and other information sources, including background or police checks; `,
            point6: `Comply with our legal obligations, protect the health and well-being of our Users, admins, admin’s employees and members of the public; `,
            point7: `Resolve disputes with our User; `,
            point8: `Enforce our agreements with third parties; `,
            point9: `Comply with the law, respond to legal requests, prevent harm and protect our rights; `,
            point10: `Enforce our terms and other policies (e.g. Non-discrimination policy); and `,
            point11: `In connection with the activities above, we may conduct profiling based on your interactions with the App, your profile information and other content you submit, and information obtained from third parties. In limited cases, automated processes could restrict or suspend access to the  App if such processes detect activity that we think poses a safety or other risk to My Protech, our community, or third parties. If you would like to challenge the decision based on the automated process, please reach out via the contact information available below. `,
          },
          paymentService: {
            boldDesc: `Provide payment services. `,
            desc: `Personal information is used to enable or authorize third parties to use payment services: `,
            point1: `Detects and prevents money laundering, fraud, abuse, and security incidents. `,
            point2: `Conduct security investigations and risk assessments. `,
            point3: `Comply with legal obligations (such as anti-money laundering regulations). `,
            point4: `Enforce the payment terms and other payment policies. `,
            point5: `Provide and improve the payment services. `,
          },
          title7: `Sharing and Disclosure `,
          title8: `Sharing with your consent or at your direction `,
          paragraph4: `Where you provide consent, we share your information as described at the time of consent, such as when authorizing a third-party application or website to access your account. `,
          paragraph5: `Where permissible with applicable laws, we may use certain information about you such as your email address, de-identify it, and share it with social media Apps to generate leads, drive traffic to the App or otherwise promote our products and services. `,
          title9: `Sharing between Users `,
          facilitateCollection: {
            desc: `To help facilitate collections or other interactions between Users, we may need to share certain information such as: `,
            point1: `When a collection pickup request is made or a dispute is submitted, certain information may be shared between seller(s) and buyer(s), including profile, name, cancellation history, review information, dispute outcome (when applicable) and other information you choose to share and submit. When a booking is confirmed, additional information is shared to assist with coordinating, like profile photo and phone number. `,
          },
          title10: `Complying with the law, responding to legal requests, preventing harm and protecting our rights `,
          complyLaw: {
            paragraph1: `We may disclose your information to courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with a valid legal request or to respond to claims asserted against My Protech, (iii) to respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability, (iv) to enforce and administer our agreements with Users, or (v) to protect the rights, property or personal safety of My Protech, its employees, its members, or members of the public. `,
            paragraph2: `Where appropriate, we may notify Users about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon or harm to My Protech, our Users, or expose My Protech to a claim of obstruction of justice. `,
          },
          title11: `Service providers `,
          paragraph6: `We share personal information with affiliated and unaffiliated service providers to help us run our App, including service providers that help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the Arus Oil services through third-party Apps and software tools (e.g. Through the integration with our APIs), (vi) provide customer service, advertising, or payments services, (vii) process, handle or assess insurance claims or similar claims, or (viii) facilitate non-profit and charitable activities consistent with My Protech’s mission. These providers are contractually bound to protect your personal information and have access to your personal information to perform these tasks. `,
          title12: `Corporate affiliates `,
          corporateAffiliates: {
            desc: `To support us in providing, integrating, promoting and improving the App, payment services, and our affiliates’ services, we may share personal information with our corporate partners that are related by common ownership or control. Some examples are: `,
            point1: `Sharing with payment solutions providers. In order to facilitate payments on or through the App, certain information will be shared with the relevant payment entity. `,
            point2: `Sharing with governmental agencies and bodies. In the event that aid is needed for the authorities (local and international), certain information will be shared to relevant parties to facilitate the execution and planning of such events. In some conditions when said event will be funded by these bodies, bank details and personal details will be shared. `,
          },
          title13: `Other important information `,
          title14: `Analysing your communications and experiences `,
          communicateExp: {
            paragraph1: `We may review, scan, or analyse your communications and experiences on the App for reasons outlined in the “how we use the information collected” section of this policy, including fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, enforcing our `,
            reference1: `terms of service`,
            paragraph2: `, and customer support purposes. For example, as part of our fraud prevention efforts, we scan and analyse messages to mask contact information and references to other sites. In some cases, we may also scan, review, or analyse messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. Occasionally we may need to manually review communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyse your messaging communications to send third-party marketing messages to you and we will not sell reviews or analyses of these communications. `,
          },
          title15: `Linking third-party accounts `,
          thirdPartyAcc: {
            desc: `You can link your account with certain third-party services like social networks. Your contacts on these third-party services are referred to as “friends.” When you direct the data sharing by creating this link: `,
            point1: `Some of the information provided to us from linking accounts may be published on your public profile; `,
            point2: `Your activities on the App may be displayed to your friends on the App and/or that third-party service; `,
            point3: `A link to your public profile on that third-party service may be included in your public profile; `,
            point4: `Other users may be able to see any friends that you may have in common with them, or that you are a friend of their friend if applicable; `,
            point5: `Information you provide to us from the linking of your accounts may be stored, processed and transmitted for fraud prevention and risk assessment purposes; and `,
            point6: `Publication and display of information that you provide to the App through this linkage is subject to your settings and authorizations on the Arus Oil App and the third-party service. `,
          },
          title16: `Third-party partners and integrations `,
          thirdPartyPartner: {
            paragraph1: `Parts of the App may link to third-party services, not owned or controlled by My Protech, such as Google maps/ Earth / Engine Mailer. Use of these services is subject to the privacy policies of those providers, such as `,
            reference1: `Google Maps/Earth additional terms of use `,
            reference2: `and Google privacy policy `,
            paragraph2: `(see `,
            reference3: `here `,
            paragraph3: `for more information on how Google uses information). The App does not own or control these third parties and when you interact with them you are providing your information to them. `,
          },
          title17: `Managing your information `,
          paragraph19: `You can access and update some of your personal information through your account settings.`,
          title18: `Data access and portability `,
          paragraph20: `In some jurisdictions, applicable laws may entitle you to request certain copies of your personal information or information about how we handle your personal information, request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format, and/or request that we transmit this information to another service provider (where technically feasible). `,
          title19: `Email and Contact Details `,
          paragraph21: `We will normally use email or your provided contact number to contact you, and this may include occasional notifications about reminders, upcoming events, and updates if you have chosen this option in your account preferences. `,
          paragraph22: `We will not pass your email details or other contact details onto third parties unless we believe it is appropriate as outlined in the previous section. `,
          paragraph23: `We encourage you to email us with your questions or comments as we use email as a means of receiving feedback from our Users. `,
          title20: `Social Media (Facebook, Instagram, Whatsapp)`,
          paragraph24: `Part of our advertisement and promotion happens on social media. Having “like” or “follow” us on these platforms allows us to provide up to date information to our Users. User’s information on these platforms will be used solely for promotion purposes and we assure you this information will not be passed to any third party.  `,
          title21: `Security`,
          paragraph25: `While no organisation can guarantee perfect security, we are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorised access, loss, destruction, or alteration. `,
          title22: `Changes to this privacy policy `,
          paragraph26: `We reserve the right to modify this privacy policy at any time in accordance with applicable laws. If we do so, we will post the revised privacy policy with an updated “last updated” date at the top. In case of material changes, we will also provide you with notice of the modification by email at least thirty (30) days before the effective date. You can cancel your account should you disagree with the revised privacy policy. If you do not cancel your account within the time stipulated, the revised privacy policy becomes effective and your continued access to or use of the App will be subject to the revised privacy policy. `,
          title23: `Contacting us and/or challenging compliance `,
          contact: {
            paragraph1: `Please contact us for anything to do with this policy, including questions or comments, or to challenge our compliance with this policy, via the details below : `,
            companyName: `My Protech Sdn Bhd (1307580-A) `,
            address1: `IOI Rio Office Tower,`,
            address2: `Bandar Puteri Puchong, Selangor, Malaysia `,
            address3: `03-56129935 / info@arusoil.com `,
          },
        },
      },
      referFriend: {
        referralForm: {
          title: "Refer To A Friend",
          description:
            "Invite your friend to start recycling used cooking oil with you. Make sure they sign up with your unique referral link so that both of you will get a 10% increase in the purchasing price of used cooking oil once your friend has started recycling once.",
        },
        cardSegment: {
          referralSegmentPost: [
            {
              title: "Step 1 - Share Your Referral Link",
              desc:
                "Invite your friends to recycle used cooking oil by sharing your unique referral link.",
              img: ShareImage,
            },
            {
              title: "Step 2 - Start Recycling",
              desc:
                "Once your friend recycles used cooking oil in Arus Oil, both of you will get up to a 10% rate increase in the purchasing rate for 3 months.",
              img: StepCollect,
            },
            {
              title: "Step 3 - Invite More Friends",
              desc:
                "You can refer and invite as many friends to recycle used cooking oil at Arus Oil as you want",
              img: RepeatRecycle,
            },
          ],
          title: "How it works",
        },
        referProgramme: {
          title: "Arus Oil Referral Programme",
          description: "Learn more about how referral works at Arus Oil.",
          viewFaqs: "View FAQs",
          viewReferral: "View Referrals",
        },
      },
      faqs: {
        title: "Frequently Asked Questions",
        faqsList: [
          {
            question: "What type of oil do you collect?",
            answer:
              "We strictly collect used cooking oil only. We do not collect engine or black oil",
          },
          {
            question: "What exactly is used cooking oil?",
            answer:
              "Used Cooking Oil, usually shortened as UCOs, are oils and fats that have been used for cooking or frying. UCOS can be in the form of vegetable oil such as corn oil, canola oil, olive oil, palm oil, etc. Leftover or used cooking oil should not be used too many times.",
          },
          {
            question: "How do I start recycling my used cooking oil?",
            answer:
              "Filter the used cooking oil with a sieve and ensure there is no mixture of water or sediments in the used cooking oil. Place used cooking oil in a suitable closed plastic or metal container.",
          },
          {
            question: "Do you provide used cooking oil storage container?",
            answer:
              "Yes. The storage containers are provided according to the volume of used cooking oil generated by household or restaurant. Contact us to have a better understanding on this at +6013-7311007.",
          },
          {
            question: "Where are you located and what areas do you service?",
            answer:
              "We are currently servicing Selangor, Negeri Sembilan, Penang and W.P. Kuala Lumpur. Do not hesitate to sign up as we will be looking to have a presence in every state.",
          },
          {
            question: "Is this service free?",
            answer:
              "Yes, the collection service is totally free of charge. In fact, the used cooking oil is purchased from you!",
          },
          {
            question: "How often is the oil collected?",
            answer:
              "Within 3-5 working days upon request for collection with a minimum collection weight of 5 kg.",
          },
          {
            question:
              "What happens to my used cooking oil after picked up from my store or house?",
            answer:
              "The used cooking oil will be filtered and lab tested before converting it as Bio-diesel.",
          },
          {
            question: "How much will I get for my used cooking oil?",
            answer:
              "You can view the purchasing rates on your dashboard once you have signed up.",
          },
          {
            question: "Is Arus Oil a certified ISCC collector?",
            answer:
              "We are in compliance with ISCC methodologies as a dependent collector. For more information, visit our Carbon Savings web page.",
          },
        ],
      },
      referralFaqs: {
        title: "Referral Programme",
        faqs: {
          faqlist: [
            {
              question: "What is Arus Oil Referral Programme ?",
              answer:
                "• Arus Oil Referral programme allows you and your referee to enjoy a 10% increase in the purchasing price of your used cooking oil for 3 months. \n\n" +
                "• For every friend that you have referred and have recycled in Arus Oil at least once, both of you are entitled to a 10% increase in the purchasing price of your used cooking oil for 3 months. \n\n",
            },
            {
              question: "Is there a limit to referring friends ?",
              answer:
                "• You can refer as many friends as you like! We do not have any limit on the number of friends you can refer.",
            },
            {
              question: "Are there any requirements to refer friends ?",
              answer:
                "• Your referee must not have an existing account.\n\n" +
                "• Your referee must sign-up via the referral link.\n\n" +
                "• After your referee's registration, your referee must recycle his/her used cooking oil of a minimum of 5 kg once for both of you to enjoy a 10% increase in the purchasing price",
            },
            {
              question: "How to refer a friend ?",
              answer:
                "• Log in to your Arus Oil account.\n\n" +
                "• Click on the 'Refer a friend' button at the top of the page. If you are on a mobile device, click on the 'Hamburger Icon' to find the 'Refer a friend' button \n\n" +
                "• Copy your individual link, and send it to your friend(s) via email, SMS, or your favourite social media platform \n\n" +
                "• When you have invited your friend and your friend have recycled his/her used cooking oil at least once, both of you have qualified for the referral programme. \n\n" +
                "• You can track your referee(s)' progress by looking at 'Your Referral History' in the 'Profile' section. \n\n",
            },
            {
              question:
                "I have some suggestions to improve Arus Oil Referral Programme.",
              answer:
                "• Feel free to let us know of your constructive feedback on info@arusoil.com",
            },
          ],
        },
      },
      recycleUCO: {
        heroTitle: "Getting Started – Arus Oil​",
        heroDescription:
          "Since 2017, Arus Oil started collecting used cooking oil by setting up a designated oil collection drum at a park located at SS14, Subang Jaya, Selangor. Residents nearby will head to the park to pour their used cooking oil to the drum. \n\n Starting from 2021, Arus Oil is digitalising our oil collection programme by leveraging on cloud systems. With one tap of a finger, Arus Oil team will collect your used cooking oil at your doorstep. View below for more explaination.",
        leftContentCard1: {
          title: "Step 1 – Collecting Used Cooking Oil",
          description:
            "Collecting used cooking oil may sound complicated but it is easy once you have the correct cooking equipment. Once you have finished with deep-frying or cooking your ingredients, filter your excess used cooking oil by pouring it to a beaker or bottle with the help of a colander. \n\n The purpose of using a colander is to filter away from any food debris and smoothen the recycling process. ",
        },
        rightContentCard1: {
          title: "Step 2 – Store Used Cooking Oil​",
          description:
            "You can store your used cooking oil collected at a glass bottle or jerry can. Ensure that you store the bottle at a non-flammable place with room temperature. \n\n ​For retail restaurants that contain a high volume of used cooking oil, we will recommend you store the used cooking oil with a barrel. Do contact our email if additional support is being required.",
        },
        leftContentCard2: {
          title: "Step 3 – Create An Account​",
          description:
            "Create an account at Sign Up page, we will collect your information to understand you better. Arus Oil takes privacy of all data that is collected seriously. ​\n\n Verification email will be sent to your email for verification upon signing up.",
        },
        rightContentCard2: {
          title: "Step 4 – Schedule An Appointment​",
          description:
            "Schedule an appointment for door-to-door collection of your used cooking oil at the Dashboard page. Place your preferred day and time for collection to allow arrangement for our logistic team. \n\n Do take note that a minimum of 5kg of used cooking oil is required for a door-to-door collection. Arus Oil's logistic team will contact you in 1-2 working days once you have scheduled an appointment. \n\n For any further questions, please visit our Frequently Asked Question page",
        },
      },
      overlay: {
        text: "Please hold on while we are verifying your account",
      },
      signUp: {
        text: "Sign Up Now",
      },
      leftContentCard: {
        title: "Door to Door Collection",
        content:
          "Arus Oil provides a door-to-door collection service to collect your used cooking oil (UCO). Simply get started by creating an account and place your preferred day, time, and address for collection arrangement.",
        button: "Arrange A Collection",
      },
      pricing: {
        paragraph1: "Transparency in Pricing",
        paragraph2:
          "Selling rates of your used cooking oil (UCO) may fluctuate according to supply and demand. Sign up to Arus Oil to get the latest selling rates.",
        subtitle1: "Volume Based",
        subtitle2:
          "Enjoy a higher selling rates when you sell your used cooking oil (UCO) in a high bulk of volume",
        subtitle3: "Promotion",
        content:
          "Create an account and get notified with the latest available promotions to be entitled with a better selling rates of used cooking oil (UCO)",
        subtitle4: "Collection",
        description:
          "The price of used cooking oil (UCO) collected through door-to-door collection will be different from the price of used cooking oil (UCO) collected at the collection station. Please refer to the data on the graph.",
        button: "View FAQs",
      },
      mission: {
        paragraph1:
          "75% Of Global Power Consumption is powered by Fossil Fuels",
        paragraph2: "Oil will end by 2052 - 30 years time",
        paragraph3: "Ensuring Environment Sustainability via Recycling",
        paragraph4:
          "Our global energy resources are steadily decreasing due to the increase of consumption of mankind. Severe problems may aris if this condition continues as all available non renewable energy resources such as natural gas, oil and coal will deplete. According to calculation and forecast, non renewable energy will be exhausted within the next 50 years.",
        paragraph5:
          "Upon reaching its end of life, used cooking oil is an often underutilized resource. After oil is used for cooking, it is often disposed instead of being repurposed. Individuals and restaurants should establish sustainable and effective oil recycling plans which in fact will have reduction of health risk, protection of the environment via renewable energy and zero waste, operational cost reducing impact or revenue inflow.",
        paragraph6:
          "Turns out, you can start today by recycling your used cooking oil with Arus Oil. Your used cooking oil will be filtered and blended to ensure it meets bio-diesel specifications for industrial usage. (Eg. Automobile)",
      },
      clients: {
        paragraph1: "Working closely with partners",
        paragraph2:
          "Since incorporated, we are fortunate to be able to work with organisation & individuals who are commited to environment sustainability.",
      },
      featured: {
        title: "As Seen On",
      },
      subscribeAction: {
        title: "Join Arus Oil App Now !",
        button: "Sign Up Now",
      },
      createAppointment: {
        title: "Create An Appointment",
        selectInput1: "Preferred Day",
        selectInputPlaceholder1: "Select the Preferred Day",
        selectInput2: "Preferred Time for Collection",
        selectInputPlaceholder2: "Select the Preferred Day",
        textInput1: "Phone",
        textInput2: "Customer Name",
        addressInput: "Addresss",
        addressPlaceholder: "Enter your address",
        uploadInput: "Upload an image of the collected UCO",
        uploadInputBtnText: "Upload a file",
        numberInput1: "Estimate Weight of UCO (kg)",
        numberInputPlaceholder: "Enter you estimated weight",
        remarks: "Additional Remarks",
        remarksPlaceholder: "Enter your additional remarks",
        btnText: "Submit",
      },
      scheduleCondition: {
        phoneError: `Please enter your contact number in the correct format of ${utilPhonePlaceholder()} (Example)`,
        nameError: `Please enter your name`,
        addressError: "Please select your address from the suggestions given",
        preferredTimeError: "Please select your preferred time for collection",
        preferredDayError: "Please select your preferred day for collection",
        estimatedWeightError: "Your collected UCO has be to at least 5kg",
        noImageError: "Please upload an image file",
        ImageTooLargeError: "File size cannot exceed 5MB",
      },
    },
    bm: {
      header: {
        signUp: "Daftar",
        signIn: "Log Masuk",
        existingCustomer: "Sudah ada akaun?",
        dashboard: "Papan Pemuka",
      },
      footer: {
        legal: "Syarikat",
        vision:
          "Menjamin kelestarian alam sekitar dengan mengitar semula minyak masak terpakai",
        copyright: `© ${moment().format(
          "YYYY"
        )} My Protech Sdn. Bhd.(1307580-A) Hak cipta terpelihara.`,
      },
      language: ["Inggeris", "Melayu", "Cina"],
      home: {
        ecf:
          "Kami memerlukan undian anda untuk membersihkan longkang di pusat bandar Kuala Lumpur dengan meningkatkan kesedaran tentang kitar semula ♲. Undi untuk kami sekarang: ",
        heroTitle: "Mengitar semula minyak masak terpakai anda dengan mudah",
        heroDescription:
          "Mulakan menjual minyak masak terpakai anda dengan lancar dalam satu aplikasi. Lihat harga jualan minyak masak terpakai setiap hari, hubungi logistik untuk pengumpulan dan lihat pembayaran yang dibuat kepada anda, semuanya di aplikasi Arus Oil",
      },
      mpobLicense: {
        title: "Pengendali Berlesen - Arus Oil",
        desc:
          "Lesen MPOB diperlukan untuk mengumpulkan / membeli minyak masak terpakai. Minyak masak terpakai (Kelapa Sawit) dikelaskan sebagai minyak kelapa sawit (SPO) di bawah Peraturan Perlesenan Lembaga Minyak Sawit Malaysia 2005. \n\n Arus Oil adalah pengendali berlesen di bawah MPOB dengan nombor lesen kami sebagai 619709017000. Beroperasi dengan lesen MPOB memastikan semua minyak masak terpakai yang dikumpulkan ditukar kepada biodiesel dan bukan untuk penggunaan haram yang akan membahayakan masyarakat dan alam sekitar.",
      },
      carbonEmission: {
        carbonTopTitle: {
          carbonTitleBlack: "Terima Laporan tentang Pelepasan Karbon Dijimat",
          carbonDescription:
            "Pelepasan karbon adalah gas rumah hijau (GHG) yang dikeluarkan daripada penggunaan bahan api kenderaan. Dengan mengitar semula minyak masak terpakai (UCO), anda boleh melihat jumlah pelepasan karbon yang anda jimat dalam aplikasi Arus Oil. Minyak masak yang dikitar semula boleh bertukar kepada biodiesel yang lebih mesra alam berbanding diesel konvensional, dan membantu mengurangkan sehingga 80% pelepasan karbon ke atmosfera.",
          carbonGetStarted: "Akan Datang",
          carbonTitle2: "Arus Oil Mematuhi Syarat ISCC",
          carbonDescription2:
            "Fakta menarik, adakah anda tahu bahawa kami mematuhi syarat ISCC (International Sustainability & Carbon Certification)? ISCC ialah sistem sijil antarabangsa yang bertujuan untuk menggabungkan kriteria kemampanan dan menyokong rantaian bekalan yang mampan. \n\n Ini adalah penting untuk mengenal pasti keberkesanan penukaran biodiesel dengan mengambil kira jarak pengumpulan dari titik asal minyak masak terpakai (e.g alamat anda).",
        },
        carbonContent: {
          carbonTitle3:
            "Metodologi Kami Dalam Mengira Pelepasan Karbon Dijimatkan",
          carbonDescription3:
            "Kami menyediakan ketelusan dan akauntabiliti pelepasan karbon yang anda jimat dengan melaporkan jumlah minyak masak terpakai yang anda kitar semula dan jarak yang dilalui oleh pasukan logistik kami ke lokasi anda. ",
          carbonHeader1: "Jarak yang Dilalui",
          carbonCaption1:
            "Aplikasi Arus Oil kami akan merekodkan jarak perjalanan semasa mengumpul UCO dan mengira pelepasan karbon berdasarkan penggunaan petrol/diesel pengangkutan.",
          carbonHeader2: "Pelepasan Karbon Dijimatkan Berdasarkan Berat",
          carbonCaption2:
            "Kami menguatkuasakan dasar berat minimum 5kg UCO untuk pengumpulan pintu ke pintu bagi memastikan penjimatan karbon. Selain itu, algoritma papan pemuka kami mengoptimumkan koleksi kami daripada semua jadual berdekatan untuk memastikan kecekapan penggunaan petrol/diesel",
          carbonButtonText: "Lihat Soalan Lazim",
        },
      },
      jmbInitiative: {
        jmbTopTitle: {
          jmbTitleBlack:
            "Kempen Mengitar Semula Minyak Masak Terpakai dengan Badan Pengurusan",
          jmbDescription:
            "Kami bekerjasama dengan Badan Pengurusan Bersama (JMB) atau Majlis Pengurusan (MC) untuk meningkatkan kesedaran tentang mengitar semula minyak masak terpakai di kalangan penduduk melalui kempen promosi. \n\nDengan bekerjasama dengan kami untuk menggalakkan usaha kitar semula, fenomena sinki atau paip tersumbat mungkin dapat dikurangkan kerana penduduk dimaklumkan dengan kaedah yang betul untuk membuang minyak masak terpakai mereka.",
          jmbGetStarted: "Dapatkan risalah PERCUMA kami",
          jmbTitle2: "Mengapa Mumupuk Amalan Kitar Semula?",
          jmbDescription2: `Adakah anda tahu bahawa setiap tahun, terdapat kira-kira 120 juta tan metrik minyak masak dibuang di Malaysia? Minyak masak terpakai (UCOs) yang tidak dirawat di dalam kumbahan sering menarik serangga perosak seperti tikus dan lipas yang akhirnya menyebabkan jangkitan penyakit kepada masyarakat setempat. \n\n Selain mencemarkan air kita dan merosakkan rantaian makanan akuatik kita, pembuangan besar minyak masak terpakai kerap menyumbat saliran dalam kumbahan domestik dan menyebabkan air bertakung, dan akhirnya menyebabkan banjir semasa sesi monsun di Malaysia. \n\n Dengan Arus Oil, anda boleh memainkan peranan anda dalam menyelamatkan dan mempromosikan persekitaran hidup yang sihat dengan mengitar semula minyak masak terpakai dan menukarnya kepada bio-diesel yang digunakan secara meluas sebagai bahan api alternatif kepada lori dan van industri kuasa.`,
        },
        jmbContent: {
          jmbTitle3: "Apa yang Kami Akan Sediakan untuk Anda",
          jmbDescription3:
            "Kami mempunyai pengalaman dalam membantu anda melancarkan program kitar semula minyak masak terpakai di komuniti dari Selangor, Pulau Pinang dan negeri lain di Malaysia.",
          jmbHeader1: "Bahan Pemasaran",
          jmbCaption1:
            "Pasukan kami akan menyediakan risalah pemasaran dan media untuk kempen promosi anda dan mengadakan pengucapan pengenalan tentang mengitar semula minyak masak terpakai kepada penduduk anda.",
          jmbHeader2: "Portal Admin",
          jmbCaption2:
            "Kami akan menjana kod promosi untuk menggalakkan komuniti anda mengitar semula minyak masak terpakai dan anda boleh menyemak penyertaan mereka dalam portal pentadbir yang kami cipta khas untuk anda.",
        },
        jmbGetInTouch: {
          jmbTitle4: "Hubungi Kita",
          jmbDesc:
            "Untuk sebarang pertanyaan JMB, sila hubungi kami melalui kontak yang disenaraikan",
          jmbSmallHeader1: "Pejabat",
        },
      },
      ngoInitiative: {
        ngoTopTitle: {
          heroTitleBlack:
            "Menyumbang kepada komuniti dengan mengitar semula UCO",
          description:
            "Jadikan perbuatan baik kecil anda memberi impak yang besar dengan menderma pulangan tunai anda daripada mengitar semula minyak masak terpakai kepada perusahaan sosial tempatan atau organisasi NGO. \n\n Selain mengitar semula minyak masak terpakai anda untuk ditukar kepada biodiesel untuk kegunaan kenderaan dan mesin perindustrian, anda juga boleh menderma wang tunai yang dibayar kepada NGO untuk memerangi isu sosial dan alam sekitar pada masa yang sama.",
          ngoGetStarted: "Mulakan Sekarang",
          ngoTitle2: "Mengapa Kami Membenarkan Derma?",
          ngoDescription2:
            "Rakyat Malaysia boleh dikategorikan kepada tiga kumpulan pendapatan berbeza, B40, M40 & T20. Walaupun kumpulan B40 sering mengitar semula minyak masak terpakai untuk mendapatkan pulangan tunai sebagai pendapatan tambahan untuk menampung kehidupan mereka, kami menyediakan pilihan untuk kumpulan M40 dan T20 yang memilih untuk menderma pulangan tunai yang mereka perolehi daripada mengitar semula minyak masak terpakai. \n\n Dengan menderma pulangan tunai tambahan, ia membantu menyampaikan kesedaran menangani isu sosial di kalangan rakyat Malaysia, dan pada masa yang sama, anda boleh menikmati pengecualian cukai daripada derma anda kepada mana-mana organisasi amal yang diluluskan oleh kerajaan.",
        },
        ngoFooter: {
          ngoTitle1: "Organisasi Yang Kami Bekerjasama",
          ngoCaption1:
            "Kami bekerjasama rapat dengan NGO tempatan dan pertubuhan amal untuk menyumbang kepada masyarakat dengan memerangi isu sosial. Sertai kami di halaman Donees/Donation aplikasi Arus Oil untuk melihat lebih banyak organisasi NGO.",
          ghcaTitle: "Persatuan Great Heart Charity",
          ghcaType: "Pesakit Dialisis, Orang Tua, Anak Yatim, OKU, Oang Asli",
          ghcaDescription:
            "Persatuan Great Heart Charity ialah sebuah pertubuhan amal bukan untung di Malaysia. Mereka bertujuan untuk memberikan bantuan kepada golongan yang memerlukan. Nilai teras mereka adalah menjadikan pemberian amal sebagai sebahagian daripada kehidupan setiap orang.",
          mrcsTitle: "Persatuan Bulan Sabit Merah Malaysia",
          mrcsType: "Populasi Terdedah - Tanpa Dokumen, Gelandangan, Migran",
          mrcsDescription:
            "Persatuan Bulan Sabit Merah Malaysia ialah pertubuhan bukan untung yang berdedikasi untuk tindakan dan perkhidmatan kemanusiaan.",
          wawTitle: "What A Waste",
          wawType: "Komuniti B40",
          wawDescription:
            "Platform What A Waste (WaW) dibina untuk mengurangkan jumlah makanan baik yang dibuang di kedai makanan setiap hari hanya kerana ia belum dijual.",
          yckTitle: "Yayasan Chow Kit",
          yckType: "Anak bawah umur di Kuala Lumpur",
          yckDescription:
            "Yayasan Chow Kit ialah pusat krisis dan penghantaran 24 jam yang menyediakan makanan, aktiviti terapi, pengurusan kes, dan program pendidikan untuk kanak-kanak berisiko Chow Kit.",
        },
      },
      privacypolicy: {
        termsandcondition: {
          title1: `Arus Oil - Dasar Privasi`,
          paragraph1: `My Protech Sdn Bhd (1307580-A) ("My Protech") menjamin bahawa kami akan mengambil langkah berjaga-jaga untuk memastikan privasi dan data peribadi anda dilindungi.`,
          paragraph2: `Dasar ini memperincikan bagaimana kami menguruskan maklumat peribadi anda dan bagaimana menghubungi kami sekiranya anda mempunyai pertanyaan.`,
          definition: {
            title: `Definisi`,
            paragraph1: `Istilah yang tidak ditentukan dalam dasar privasi ini mempunyai definisi yang sama seperti dalam kami `,
            reference1: `syarat perkhidmatan `,
            terms: `("Syarat")`,
          },
          title2: `Maklumat Peribadi yang kami kumpulkan`,
          title3: `Maklumat yang diperlukan untuk pembuatan akaun di Arus Oil App `,
          informationCreation: {
            desc:
              "Kami mengumpulkan maklumat peribadi anda semasa anda membuat akaun di Aplikasi Arus Oil (“Aplikasi”). Tanpa itu, kami mungkin tidak dapat memberikan anda semua perkhidmatan yang diminta. Maklumat tersebut merangkumi:",
            bold1: `Maklumat hubungan, akaun, maklumat peribadi. `,
            point1:
              "Seperti nama depan, nama belakang, nombor telefon, alamat pos, alamat e-mel, tarikh lahir, dan profesion anda.",
            bold2: `Maklumat pengesahan dan pembayaran identiti. `,
            point2:
              "Seperti nombor kad pengenalan anda atau pengesahan lain, maklumat akaun bank atau akaun pembayaran. ",
            bold3: `Maklumat peribadi tambahan. `,
            point3:
              "Seperti jantina, bahasa pilihan, bandar, dan perihalan peribadi. Sebilangan maklumat ini seperti yang ditunjukkan dalam tetapan akaun anda adalah bagian dari halaman profil awam anda dan akan dapat dilihat oleh umum. ",
            bold4: `Maklumat lain. `,
            point4:
              "Seperti ketika anda mengisi borang, menambahkan maklumat ke akaun anda, menjawab tinjauan, menjawab forum komuniti, mengambil bahagian dalam promosi, berkomunikasi dengan pasukan penjagaan pelanggan kami dan Pengguna lain, atau berkongsi pengalaman anda dengan kami. Ini mungkin merangkumi maklumat kesihatan atau perjalanan. ",
          },
          title4: `Maklumat dikumpulkan secara mandatori semasa menempah koleksi`,
          informationMandatorily: {
            desc:
              "Kami secara automatik mengumpulkan maklumat peribadi semasa anda menempah koleksi. Maklumat ini mungkin merangkumi: ",
            bold1: `Maklumat lokasi geografi. `,
            point1:
              "Seperti lokasi tepat atau anggaran yang ditentukan dari alamat IP anda atau GPS peranti mudah alih bergantung pada tetapan peranti anda. Kami juga dapat mengumpulkan informasi ini ketika Anda tidak menggunakan Aplikasi jika Anda mengaktifkannya melalui tetapan atau izin peranti. ",
            bold2: `Maklumat penggunaan. `,
            point2:
              "Seperti halaman atau kandungan yang anda lihat, tempahan yang telah anda buat, dan tindakan lain di Arus Oil App. ",
            bold3: `Data log dan maklumat peranti. `,
            point3:
              "Seperti perincian tentang cara anda menggunakan Aplikasi (termasuk jika anda mengklik pautan ke aplikasi pihak ketiga), alamat IP, tarikh dan masa akses, maklumat perkakasan dan perisian, maklumat peranti, maklumat peristiwa peranti, pengecam unik, data keran, data kuki, dan halaman yang telah anda lihat atau terlibat sebelum atau selepas menggunakan Aplikasi. Kami mungkin mengumpulkan maklumat ini walaupun anda belum membuat akaun Arus Oil atau log masuk. ",
            bold4: `Kuki dan teknologi serupa.`,
            bold5: `Maklumat transaksi pembayaran. `,
            point5:
              "Seperti instrumen pembayaran yang digunakan, tarikh dan waktu, jumlah pembayaran, tarikh luput instrumen pembayaran, kod pos penagihan, alamat e-mel, maklumat IBAN, alamat anda dan perincian transaksi lain yang berkaitan. ",
            bold6: `Pengisytiharan peribadi. `,
            point6:
              "Seperti borang perisytiharan kesihatan dan perisytiharan kewangan. ",
          },
          title5: `Maklumat peribadi yang dikumpulkan dari pihak ketiga `,
          informationCollected: {
            desc: `Kami mengumpulkan maklumat peribadi dari sumber lain, seperti: `,
            bold1: `Perkhidmatan pihak ketiga. `,
            point1: `Sekiranya anda memautkan, menyambung, atau log masuk ke Aplikasi dengan perkhidmatan pihak ketiga (misalnya Google, Facebook, Instagram), anda mengarahkan perkhidmatan tersebut untuk menghantar maklumat seperti pendaftaran, senarai rakan, dan maklumat profil kepada kami seperti yang dikendalikan oleh perkhidmatan tersebut atau seperti yang dibenarkan oleh anda melalui tetapan privasi anda dengan perkhidmatan tersebut. `,
            bold2: `Maklumat latar belakang. `,
            point2: `Sejauh yang diizinkan oleh undang-undang yang berlaku dan dengan persetujuan anda jika diperlukan, kami dapat memperoleh pemeriksaan polis tempatan, latar belakang atau pemeriksaan pelaku seks yang didaftarkan. Kami mungkin menggunakan maklumat anda, termasuk nama penuh dan tarikh lahir anda, untuk mendapatkan laporan tersebut. `,
            bold3: `Organisasi, badan dan agensi lain yang mengambil bahagian. `,
            point3: `Sekiranya anda dijemput ke Aplikasi Arus Oil oleh organisasi, organisasi, badan atau agensi lain, anda mungkin telah menghantar maklumat peribadi mengenai diri anda seperti alamat e-mel anda atau maklumat hubungan lain. `,
            bold4: `Sumber lain. `,
            point4: `Sejauh yang diizinkan oleh undang-undang yang berlaku, kami mungkin akan menerima maklumat tambahan mengenai anda, seperti rujukan, data demografi atau maklumat untuk membantu mengesan masalah penipuan dan keselamatan dari penyedia perkhidmatan dan / atau rakan pihak ketiga, dan menggabungkannya dengan maklumat yang kami ada mengenai anda . Sebagai contoh, kami mungkin menerima hasil pemeriksaan latar belakang atau amaran penipuan dari penyedia perkhidmatan pengesahan identiti untuk digunakan dalam usaha pencegahan penipuan dan penilaian risiko kami. Kami mungkin menerima maklumat mengenai anda dan aktiviti anda di dalam dan di luar Aplikasi, atau mengenai pengalaman dan interaksi anda dari rakan kami. Kami mungkin menerima maklumat kesihatan, termasuk tetapi tidak terhad kepada maklumat kesihatan yang berkaitan dengan penyakit berjangkit. `,
          },
          title6: `Bagaimana kita menggunakan maklumat yang dikumpulkan `,
          provideService: {
            boldDesc: `Menyediakan, memperbaiki, dan mengembangkan perkhidmatan di Arus Oil App. `,
            desc: `Kami menggunakan maklumat peribadi untuk: `,
            point1: `Membolehkan anda mengakses Aplikasi; `,
            point2: `Membolehkan anda berkomunikasi dengan pengguna dan pentadbir lain; `,
            point3: `Lakukan analisis, debug dan jalankan penyelidikan; `,
            point4: `Sediakan perkhidmatan pengumpulan di lokasi anda; `,
            point5: `Menyediakan penyelarasan dan penganjuran acara di dekat anda; `,
            point6: `Kirimkan mesej, kemas kini, amaran keselamatan, dan pemberitahuan akaun kepada anda; `,
            point7: `Peribadikan dan sesuaikan pengalaman anda berdasarkan interaksi anda dengan Aplikasi, sejarah carian dan tempahan anda, maklumat dan pilihan profil anda, dan kandungan lain yang anda kirimkan. `,
          },
          maintainTrust: {
            boldDesc: `Buat dan jaga persekitaran yang dipercayai dan selamat. `,
            desc: `Kami menggunakan maklumat peribadi untuk: `,
            point1: `Mengesan dan mencegah penipuan, spam, penyalahgunaan, insiden keselamatan dan keselamatan, dan aktiviti berbahaya yang lain; `,
            point2: `Mengkaji dan memerangi diskriminasi yang selaras dengan dasar bukan diskriminasi kami; `,
            point3: `Menjalankan siasatan keselamatan dan penilaian risiko; `,
            point4: `Mengesahkan atau mengesahkan maklumat yang diberikan oleh anda; `,
            point5: `Melakukan pemeriksaan terhadap pangkalan data dan sumber maklumat lain, termasuk latar belakang atau pemeriksaan polis; `,
            point6: `Mematuhi tanggungjawab undang-undang kami, melindungi kesihatan dan kesejahteraan Pengguna, pentadbir, pekerja pentadbir dan orang ramai; `,
            point7: `Selesaikan pertikaian dengan Pengguna kami; `,
            point8: `Menguatkuasakan perjanjian kami dengan pihak ketiga; `,
            point9: `Mematuhi undang-undang, bertindak balas terhadap permintaan undang-undang, mencegah bahaya dan melindungi hak kita; `,
            point10: `Kuatkan syarat dan dasar kami yang lain (cth: Polisi tanpa diskriminasi); dan `,
            point11: `Sehubungan dengan aktiviti di atas, kami mungkin melakukan profil berdasarkan interaksi anda dengan Aplikasi, maklumat profil anda dan kandungan lain yang anda kirimkan, dan maklumat yang diperoleh dari pihak ketiga. Dalam kes yang terhad, proses automatik dapat menyekat atau menangguhkan akses ke Aplikasi jika proses tersebut mengesan aktiviti yang menurut kami menimbulkan keselamatan atau risiko lain kepada My Protech, komuniti kami, atau pihak ketiga. Sekiranya anda ingin mencabar keputusan berdasarkan proses automatik, hubungi melalui maklumat hubungan yang tersedia di bawah. `,
          },
          paymentService: {
            boldDesc: `Memberi perkhidmatan pembayaran. `,
            desc: `Maklumat peribadi digunakan untuk membolehkan atau membenarkan pihak ketiga menggunakan perkhidmatan pembayaran: `,
            point1: `Mengesan dan mencegah pengubahan wang haram, penipuan, penyalahgunaan, dan insiden keselamatan. `,
            point2: `Lakukan siasatan keselamatan dan penilaian risiko. `,
            point3: `Mematuhi tanggungjawab undang-undang (seperti peraturan anti pencucian wang). `,
            point4: `Kuatkan syarat pembayaran dan dasar pembayaran lain. `,
            point5: `Menyediakan dan meningkatkan perkhidmatan pembayaran. `,
          },
          title7: `Perkongsian dan Pendedahan `,
          title8: `Berkongsi dengan persetujuan anda atau atas arahan anda `,
          paragraph4: `Di mana anda memberikan persetujuan, kami akan berkongsi maklumat anda seperti yang dijelaskan pada masa persetujuan, seperti ketika membenarkan aplikasi atau laman web pihak ketiga untuk mengakses akaun anda. `,
          paragraph5: `Sekiranya dibenarkan dengan undang-undang yang berlaku, kami mungkin menggunakan maklumat tertentu tentang anda seperti alamat e-mel anda, nyah mengenal pasti, dan membaginya dengan Aplikasi media sosial untuk menjana petunjuk, mendorong lalu lintas ke Aplikasi atau mempromosikan produk dan perkhidmatan kami. `,
          title9: `Perkongsian antara Pengguna `,
          facilitateCollection: {
            desc: `Untuk membantu memudahkan pengumpulan atau interaksi lain antara Pengguna, kami mungkin perlu berkongsi maklumat tertentu seperti: `,
            point1: `Apabila permintaan pengambilan koleksi dibuat atau perselisihan diserahkan, maklumat tertentu dapat dikongsi antara penjual dan pembeli, termasuk profil, nama, sejarah pembatalan, maklumat ulasan, hasil pertikaian (jika ada) dan maklumat lain yang Anda memilih untuk berkongsi dan menghantar. Apabila tempahan disahkan, maklumat tambahan akan dikongsi untuk membantu penyelarasan, seperti foto profil dan nombor telefon. `,
          },
          title10: `Mematuhi undang-undang, menanggapi permintaan undang-undang, mencegah bahaya dan melindungi hak-hak kita `,
          complyLaw: {
            paragraph1: `Kami mungkin mendedahkan maklumat anda kepada pengadilan, penguatkuasa undang-undang, pihak berkuasa pemerintah atau awam, pihak berkuasa cukai, atau pihak ketiga yang diberi kuasa, jika dan sejauh mana kami diminta atau diizinkan untuk melakukannya oleh undang-undang atau di mana pendedahan itu wajar: (i) untuk mematuhi kewajiban undang-undang kami, (ii) untuk mematuhi permintaan undang-undang yang sah atau untuk menjawab tuntutan yang ditegaskan terhadap My Protech, (iii) untuk menjawab permintaan undang-undang yang sah yang berkaitan dengan penyiasatan jenayah untuk menangani dugaan atau disyaki aktiviti haram, atau untuk bertindak balas atau menangani sebarang aktiviti lain yang mungkin mendedahkan kami, anda, atau pengguna kami yang lain terhadap tanggungjawab undang-undang atau peraturan, (iv) untuk menguatkuasakan dan menguruskan perjanjian kami dengan Pengguna, atau (v) untuk melindungi hak, harta atau keselamatan peribadi My Protech, pegawainya, anggotanya, atau orang awam. `,
            paragraph2: `Sekiranya sesuai, kami dapat memberitahu Pengguna tentang permintaan hukum kecuali: (i) pemberitahuan dilarang oleh proses hukum itu sendiri, dengan perintah pengadilan yang kami terima, atau oleh undang-undang yang berlaku, atau (ii) kami percaya bahawa pemberitahuan akan sia-sia, tidak berkesan , menimbulkan risiko kecederaan atau kecederaan badan kepada seseorang atau kumpulan, atau membuat atau meningkatkan risiko penipuan atau kerosakan kepada Protech Saya, Pengguna kami, atau mendedahkan Protech Saya kepada tuntutan penghalang keadilan. `,
          },
          title11: `Penyedia perkhidmatan `,
          paragraph6: `Kami berkongsi maklumat peribadi dengan penyedia perkhidmatan yang berafiliasi dan tidak berkaitan untuk membantu kami menjalankan Aplikasi kami, termasuk penyedia perkhidmatan yang membantu kami: (i) mengesahkan identiti anda atau mengesahkan dokumen pengenalan diri anda, (ii) memeriksa maklumat terhadap pangkalan data awam, (iii) menjalankan latar belakang atau pemeriksaan polis, pencegahan penipuan, dan penilaian risiko, (iv) melakukan pengembangan produk, penyelenggaraan dan penyahpepijatan, (v) membenarkan penyediaan perkhidmatan Arus Oil melalui alat aplikasi dan perisian pihak ketiga (mis. Melalui penyatuan dengan API kami) , (vi) menyediakan perkhidmatan pelanggan, iklan, atau perkhidmatan pembayaran, (vii) memproses, menangani atau menilai tuntutan insurans atau tuntutan serupa, atau (viii) memfasilitasi aktiviti bukan untung dan amal selaras dengan misi My Protech. Penyedia ini terikat secara kontrak untuk melindungi maklumat peribadi anda dan mempunyai akses ke maklumat peribadi anda untuk melaksanakan tugas-tugas ini. `,
          title12: `Gabungan korporat`,
          corporateAffiliates: {
            desc: `Untuk menyokong kami dalam menyediakan, mengintegrasikan, mempromosikan dan meningkatkan Aplikasi, perkhidmatan pembayaran, dan perkhidmatan gabungan kami, kami dapat berkongsi maklumat peribadi dengan rakan korporat kami yang berkaitan dengan pemilikan atau kawalan bersama. Beberapa contoh adalah: `,
            point1: `Berkongsi dengan penyedia penyelesaian pembayaran. Untuk memudahkan pembayaran di atau melalui Aplikasi, maklumat tertentu akan dibagikan dengan entiti pembayaran yang berkaitan. `,
            point2: `Berkongsi dengan agensi dan badan kerajaan. Sekiranya bantuan diperlukan untuk pihak berkuasa (tempatan dan antarabangsa), maklumat tertentu akan dibagikan kepada pihak-pihak yang berkaitan untuk memudahkan pelaksanaan dan perancangan acara tersebut. Dalam beberapa keadaan apabila acara tersebut akan dibiayai oleh badan-badan ini, maklumat bank dan maklumat peribadi akan dikongsi. `,
          },
          title13: `Maklumat penting lain `,
          title14: `Menganalisis komunikasi dan pengalaman anda `,
          communicateExp: {
            paragraph1: `Kami mungkin meninjau, mengimbas, atau menganalisis komunikasi dan pengalaman anda di Aplikasi dengan alasan yang digariskan dalam bahagian "bagaimana kami menggunakan maklumat yang dikumpulkan" dalam dasar ini, termasuk pencegahan penipuan, penilaian risiko, pematuhan peraturan, penyelidikan, pengembangan produk, penyelidikan, analisis, menguatkuasakan kami `,
            reference1: `syarat perkhidmatan`,
            paragraph2: `, dan tujuan sokongan pelanggan. Sebagai contoh, sebagai sebahagian daripada usaha pencegahan penipuan, kami mengimbas dan menganalisis mesej untuk menutup maklumat hubungan dan rujukan ke laman web lain. Dalam beberapa kes, kami juga dapat mengimbas, meninjau, atau menganalisis pesan untuk men-debug, memperbaiki, dan memperluas penawaran produk. Kami menggunakan kaedah automatik di mana mungkin. Kadang-kadang kita mungkin perlu meninjau komunikasi secara manual, seperti untuk penyelidikan penipuan dan sokongan pelanggan, atau untuk menilai dan meningkatkan fungsi alat automatik ini. Kami tidak akan menyemak, mengimbas, atau menganalisis komunikasi pemesejan anda untuk menghantar pesanan pemasaran pihak ketiga kepada anda dan kami tidak akan menjual ulasan atau analisis komunikasi ini. `,
          },
          title15: `Memautkan akaun pihak ketiga `,
          thirdPartyAcc: {
            desc: `Anda boleh menghubungkan akaun anda dengan perkhidmatan pihak ketiga tertentu seperti rangkaian sosial. Kenalan anda pada perkhidmatan pihak ketiga ini disebut sebagai "kawan". Apabila anda mengarahkan perkongsian data dengan membuat pautan ini: `,
            point1: `Sebilangan maklumat yang diberikan kepada kami dari memautkan akaun mungkin disiarkan di profil awam anda; `,
            point2: `Aktiviti anda di Aplikasi boleh ditunjukkan kepada rakan anda di Aplikasi dan / atau perkhidmatan pihak ketiga tersebut; `,
            point3: `Pautan ke profil awam anda pada perkhidmatan pihak ketiga tersebut mungkin disertakan dalam profil awam anda; `,
            point4: `Pengguna lain mungkin dapat melihat rakan-rakan yang mungkin anda miliki dengan mereka, atau bahawa anda adalah rakan rakan mereka jika ada; `,
            point5: `Maklumat yang anda berikan kepada kami dari memautkan akaun anda mungkin disimpan, diproses dan dihantar untuk tujuan pencegahan penipuan dan penilaian risiko; dan `,
            point6: `Penerbitan dan paparan maklumat yang anda berikan kepada Aplikasi melalui pautan ini bergantung pada tetapan dan kebenaran anda di Aplikasi Arus Oil dan perkhidmatan pihak ketiga. `,
          },
          title16: `Rakan kongsi pihak ketiga dan penyatuan `,
          thirdPartyPartner: {
            paragraph1: `Sebahagian daripada Aplikasi boleh menghubungkan ke perkhidmatan pihak ketiga, yang tidak dimiliki atau dikendalikan oleh My Protech, seperti peta Google / Earth / Engine Mailer. Penggunaan perkhidmatan ini tertakluk pada dasar privasi penyedia tersebut, seperti `,
            reference1: `Syarat penggunaan tambahan Peta Google / Bumi `,
            reference2: `dan dasar privasi Google `,
            paragraph2: `(tengok `,
            reference3: `sini `,
            paragraph3: `untuk maklumat lebih lanjut mengenai bagaimana Google menggunakan maklumat). Aplikasi ini tidak memiliki atau mengawal pihak ketiga ini dan apabila anda berinteraksi dengan mereka, anda memberikan maklumat anda kepada mereka. `,
          },
          title17: `Menguruskan maklumat anda `,
          paragraph19: `Anda boleh mengakses dan mengemas kini beberapa maklumat peribadi anda melalui tetapan akaun anda.`,
          title18: `Akses dan mudah alih data `,
          paragraph20: `Di beberapa bidang kuasa, undang-undang yang berlaku mungkin memberi anda hak untuk meminta salinan maklumat peribadi anda atau maklumat mengenai bagaimana kami menangani maklumat peribadi anda, meminta salinan maklumat peribadi yang anda berikan kepada kami dalam format yang terstruktur, biasa digunakan, dan dapat dibaca mesin , dan / atau meminta agar kami menyampaikan maklumat ini kepada penyedia perkhidmatan lain (jika layak secara teknikal). `,
          title19: `E-mel dan Maklumat Perhubungan `,
          paragraph21: `Kami biasanya akan menggunakan e-mel atau nombor kenalan yang anda berikan untuk menghubungi anda, dan ini mungkin termasuk pemberitahuan sesekali mengenai peringatan, acara yang akan datang, dan kemas kini sekiranya anda memilih pilihan ini dalam pilihan akaun anda. `,
          paragraph22: `Kami tidak akan menyampaikan butiran e-mel anda atau maklumat hubungan lain kepada pihak ketiga melainkan kami percaya ia sesuai seperti yang digariskan di bahagian sebelumnya. `,
          paragraph23: `Kami mendorong anda untuk menghantar e-mel kepada kami dengan pertanyaan atau komen anda kerana kami menggunakan e-mel sebagai alat untuk menerima maklum balas dari Pengguna kami. `,
          title20: `Media Sosial (Facebook, Instagram, Whatsapp)`,
          paragraph24: `Sebahagian daripada iklan dan promosi kami berlaku di media sosial. Memiliki "suka" atau "mengikuti" kami di platform ini membolehkan kami memberikan maklumat terkini kepada Pengguna kami. Maklumat pengguna di platform ini akan digunakan semata-mata untuk tujuan promosi dan kami memberi jaminan bahawa maklumat ini tidak akan disampaikan kepada pihak ketiga. `,
          title21: `Keselamatan`,
          paragraph25: `Walaupun tidak ada organisasi yang dapat menjamin keselamatan yang sempurna, kami terus menerapkan dan mengemas kini langkah-langkah keselamatan pentadbiran, teknikal, dan fizikal untuk membantu melindungi maklumat anda daripada akses, kehilangan, pemusnahan, atau perubahan yang tidak sah. `,
          title22: `Perubahan pada dasar privasi ini `,
          paragraph26: `Kami berhak untuk mengubah kebijakan privasi ini setiap saat sesuai dengan undang-undang yang berlaku. Sekiranya kami melakukannya, kami akan memposting polisi privasi yang disemak dengan tarikh "terakhir dikemas kini" yang dikemas kini di bahagian atas. Sekiranya berlaku perubahan material, kami juga akan memberi anda pemberitahuan mengenai pengubahsuaian melalui e-mel sekurang-kurangnya tiga puluh (30) hari sebelum tarikh kuat kuasa. Anda boleh membatalkan akaun sekiranya anda tidak bersetuju dengan dasar privasi yang disemak semula. Sekiranya anda tidak membatalkan akaun anda dalam jangka waktu yang ditentukan, kebijakan privasi yang disemak semula menjadi efektif dan akses atau penggunaan Aplikasi anda yang berterusan akan tertakluk pada kebijakan privasi yang disemak semula. `,
          title23: `Menghubungi kami dan / atau mencabar pematuhan `,
          contact: {
            paragraph1: `Sila hubungi kami untuk apa-apa kaitan dengan dasar ini, termasuk pertanyaan atau komen, atau untuk mencabar pematuhan kami terhadap dasar ini, melalui perincian di bawah: `,
            companyName: `My Protech Sdn Bhd (1307580-A) `,
            address1: `IOI Rio Office Tower,`,
            address2: `Bandar Puteri Puchong, Selangor, Malaysia `,
            address3: `03-56129935 / info@arusoil.com `,
          },
        },
      },
      referFriend: {
        referralForm: {
          title: "Rujuk Kepada Rakan",
          description:
            "Jemput rakan anda untuk mula mengitar semula minyak masak terpakai dengan anda. Pastikan mereka mendaftar dengan pautan rujukan unik anda sehingga anda berdua akan mendapat kenaikan harga beli minyak masak terpakai 10% setelah rakan anda mula mengitar semula sekali.",
        },
        cardSegment: {
          referralSegmentPost: [
            {
              title: "Langkah 1 - Kongsi Pautan Rujukan Anda",
              desc:
                "Ajak rakan anda mengitar semula minyak masak terpakai dengan berkongsi pautan rujukan unik anda.",
              img: ShareImage,
            },
            {
              title: "Langkah 2 - Mulakan Kitar Semula",
              desc:
                "Setelah rakan anda mengitar semula minyak masak terpakai di Arus Oil, anda berdua akan mendapat kenaikan kadar harga 10% dalam kadar pembelian selama 3 bulan.",
              img: StepCollect,
            },
            {
              title: "Langkah 3 - Jemput Lebih Banyak Rakan",
              desc:
                "Anda boleh merujuk dan menjemput seberapa banyak rakan untuk mengitar semula minyak masak terpakai di Arus Oil yang anda mahukan.",
              img: RepeatRecycle,
            },
          ],
          title: "Bagaimana ia berfungsi",
        },
        referProgramme: {
          title: "Program Rujukan Arus Oil",
          description:
            "Ketahui lebih lanjut mengenai bagaimana program rujukan berfungsi di Arus Oil.",
          viewFaqs: "Lihat Soalan Lazim",
          viewReferral: "Lihat Rujukan Anda",
        },
      },
      faqs: {
        title: "Soalan Lazim",
        faqsList: [
          {
            question: "Apakah jenis minyak yang anda kumpulkan?",
            answer:
              "Kami hanya mengumpulkan minyak masak terpakai sahaja. Kami tidak mengumpulkan minyak enjin atau minyak hitam.",
          },
          {
            question: "Apa sebenarnya minyak masak terpakai?",
            answer:
              "Minyak Masak terpakai adalah minyak dan lemak yang telah digunakan untuk memasak atau menggoreng. Minyak Masak terpakai boleh dalam bentuk minyak sayuran seperti minyak jagung, minyak canola, minyak zaitun, minyak sawit, dan lain-lain. Minyak sisa atau minyak masak terpakai tidak boleh digunakan terlalu banyak kali.",
          },
          {
            question:
              "Bagaimana saya boleh mengitar semula minyak masak terpakai saya?",
            answer:
              "Tapis minyak goreng terpakai dengan ayak dan pastikan tidak ada campuran air atau sedimen di dalam minyak masak terpakai. Letakkan minyak masak terpakai di dalam bekas plastik atau logam tertutup yang sesuai.",
          },
          {
            question:
              "Adakah anda menyediakan bekas simpanan minyak masak terpakai?",
            answer:
              "Ya. Bekas simpanan disediakan mengikut jumlah minyak masak terpakai yang dihasilkan oleh isi rumah atau restoran. Hubungi kami untuk mendapatkan pemahaman yang lebih baik mengenai perkara ini di talian +6013-7311007.",
          },
          {
            question: "Di mana anda berada dan kawasan apa yang anda servis?",
            answer:
              "Kami kini melayani Selangor, Negeri Sembilan, Pulau Pinang dan W.P. Kuala Lumpur. Jangan teragak-agak untuk mendaftar kerana kami akan mencari kehadiran di setiap negeri.",
          },
          {
            question: "Adakah perkhidmatan ini percuma?",
            answer:
              "Ya, perkhidmatan kutipan adalah percuma. Sebenarnya, minyak masak terpakai dibeli dari anda!",
          },
          {
            question: "Berapa kerap minyak dikumpulkan?",
            answer:
              "Dalam masa 3-5 hari bekerja atas permintaan pengumpulan dengan berat koleksi minimum 5 kg.",
          },
          {
            question:
              "Apa yang berlaku dengan minyak masak terpakai saya setelah diambil dari kedai atau rumah saya?",
            answer:
              "Minyak masak terpakai akan ditapis dan diuji di makmal sebelum menukarnya sebagai Bio-diesel.",
          },
          {
            question:
              "Berapa banyak yang saya akan dapat untuk minyak masak terpakai saya?",
            answer:
              "Anda dapat melihat kadar pembelian di papan pemuka anda setelah mendaftar.",
          },
          {
            question: "Adakah Arus Oil pengumpul ISCC yang disahkan?",
            answer:
              "Kami mematuhi syarat ISCC sebagai pengumpul bergantung. Untuk maklumat lanjut, lawati halaman web Penjimatan Karbon kami.",
          },
        ],
      },
      referralFaqs: {
        title: "Program Rujukan",
        faqs: {
          faqlist: [
            {
              question: "Apakah Program Rujukan Arus Oil ?",
              answer:
                "• Program Rujukan Arus Oil membolehkan anda dan rakan anda menikmati kenaikan 10% harga beli minyak masak terpakai anda selama 3 bulan. \n\n" +
                "• Untuk setiap rakan yang telah anda rujuk dan kitar semula dalam Arus Oil sekurang-kurangnya sekali, anda berdua berhak mendapat kenaikan 10% harga beli minyak masak terpakai anda selama 3 bulan. \n\n",
            },
            {
              question: "Adakah had untuk merujuk rakan ?",
              answer:
                "• Anda boleh merujuk seberapa banyak rakan yang anda suka! Kami tidak mempunyai had bilangan rakan yang boleh anda rujuk.",
            },
            {
              question: "Adakah terdapat syarat untuk merujuk rakan ?",
              answer:
                "• Rakan anda tidak mesti mempunyai akaun yang sedia ada.\n\n" +
                "• Rakan anda mesti mendaftar melalui pautan rujukan.\n\n" +
                "• Setelah pendaftaran rakan anda, rakan anda mesti mengitar semula minyak masak terpakai minimum 5 kg sekali untuk anda berdua menikmati kenaikan harga beli 10%.",
            },
            {
              question: "Bagaimana merujuk rakan ?",
              answer:
                "• Log masuk ke akaun Arus Oil anda.\n\n" +
                "• Klik pada butang 'Rujuk rakan' di bahagian atas halaman. Sekiranya anda menggunakan peranti mudah alih, klik pada 'Ikon Hamburger' untuk mencari butang 'Rujuk rakan'. \n\n" +
                "• Salin pautan individu anda, dan kirimkan kepada rakan anda melalui e-mel, SMS, atau platform media sosial kegemaran anda. \n\n" +
                "• Apabila anda telah menjemput rakan anda dan rakan anda telah mengitar semula minyak masak terpakai sekurang-kurangnya sekali, anda berdua telah layak mengikuti program rujukan. \n\n" +
                "• Anda boleh mengesan progres rakan anda dengan melihat 'Sejarah Rujukan Anda' di bahagian 'Profil'. \n\n",
            },
            {
              question:
                "Saya mempunyai beberapa cadangan untuk meningkatkan Program Rujukan Arus Oil.",
              answer:
                "• Jangan ragu untuk memberitahu kami mengenai maklum balas anda di info@arusoil.com",
            },
          ],
        },
      },
      recycleUCO: {
        heroTitle: "Mulailah Kitar Semula Dengan Arus Oil",
        heroDescription:
          "Sejak 2017, Arus Oil mula mengumpulkan minyak masak terpakai dengan memasang tong pengumpulan minyak yang telah ditempatkan di sebuah taman yang terletak di SS14, Subang Jaya, Selangor. Penduduk berdekatan akan menuju ke taman untuk menuangkan minyak masak terpakai mereka ke tong sampah. \n\n Mulai tahun 2021, Arus Oil mendigitalkan program pengumpulan minyak kami dengan memanfaatkan sistem cloud. Dengan satu ketukan jari, pasukan Arus Oil akan mengumpulkan minyak masak terpakai anda di depan pintu anda. Lihat di bawah untuk penjelasan lebih lanjut.",
        leftContentCard1: {
          title: "Langkah 1 - Mengumpulkan Minyak Masak Terpakai​",
          description:
            "Mengumpulkan minyak masak terpakai mungkin terdengar rumit tetapi mudah setelah anda mempunyai peralatan memasak yang betul. Setelah anda selesai menggoreng atau memasak bahan-bahan anda, tapis minyak masak terpakai anda dengan menuangkannya ke bikar atau botol dengan bantuan saringan. \n\n Tujuan menggunakan colander adalah menyaring dari sisa makanan dan melancarkan proses kitar semula. ",
        },
        rightContentCard1: {
          title: "Langkah 2 - Simpan Minyak Masak Terpakai",
          description:
            "Anda boleh menyimpan minyak masak terpakai anda yang dikumpulkan di botol kaca atau tong jerry. Pastikan anda menyimpan botol di tempat yang tidak mudah terbakar dengan suhu bilik. \n\n ​Untuk restoran yang mengandungi minyak masak terpakai yang banyak, kami akan mengesyorkan anda menyimpan minyak masak terpakai dengan tong. Hubungi e-mel kami jika sokongan tambahan diperlukan.",
        },
        leftContentCard2: {
          title: "Langkah 3 - Daftarkan Akaun",
          description:
            "Daftarkan akaun di halaman Daftar, kami akan mengumpulkan maklumat anda untuk lebih memahami anda. Arus Oil menjaga privasi semua data yang dikumpulkan dengan serius. ​\n\n E-mel pengesahan akan dihantar ke e-mel anda untuk pengesahan semasa mendaftar.",
        },
        rightContentCard2: {
          title: "Langkah 4 - Jadualkan Temu​janji",
          description:
            "Jadualkan temujanji untuk koleksi minyak masak terpakai dari pintu ke pintu di halaman Papan Pemuka. Letakkan hari dan waktu pilihan anda untuk pengumpulan untuk membolehkan pengaturan untuk pasukan logistik kami. \n\n Untuk sebarang pertanyaan lebih lanjut, sila lawati halaman Soalan Lazim kami.",
        },
      },
      overlay: {
        text: "Sila tunggu sementara semesa kami mengesahkan akaun anda",
      },
      signUp: {
        text: "Daftarlah Sekarang",
      },
      leftContentCard: {
        title: "Koleksi Pintu ke Pintu",
        content:
          "Arus Oil menyediakan perkhidmatan pengumpulan dari pintu ke pintu untuk mengumpulkan minyak masak terpakai anda. Bermula dengan mendaftarkan akaun dan memilih hari, waktu, dan alamat untuk urusan pengumpulan.",
        button: "Susunkan koleksi",
      },
      pricing: {
        paragraph1: "Ketelusan dalam Penentuan Harga",
        paragraph2:
          "Kadar penjualan minyak masak terpakai anda mungkin berubah-ubah mengikut penawaran dan permintaan. Daftar ke Arus Oil untuk mendapatkan kadar jualan terkini.",
        subtitle1: "Berdasarkan Isipadu",
        subtitle2:
          "Nikmati kadar penjualan yang lebih tinggi apabila anda menjual minyak masak terpakai anda dalam jumlah yang banyak",
        subtitle3: "Promosi",
        content:
          "Daftarkan akaun dan dapatkan notifikasi dengan promosi terkini yang tersedia untuk mendapatkan harga jual minyak masak terpakai yang lebih baik",
        subtitle4: "Koleksi",
        description:
          "Harga minyak masak yang dikutip melalui kutipan pintu ke pintu akan berbeza dengan harga minyak masak yang dikutip di stesen kutipan. Sila rujuk data pada graf",
        button: "Melayari Soalan Lazim",
      },
      mission: {
        paragraph1:
          "75% Penggunaan Tenaga Global dikuasakan oleh Bahan Bakar Fosil",
        paragraph2: "Minyak akan berakhir menjelang 2052 - 30 tahun",
        paragraph3: "Menjamin Kelestarian Alam Sekitar melalui Kitar Semula",
        paragraph4:
          "Sumber tenaga global kita semakin berkurang disebabkan oleh peningkatan penggunaan manusia. Masalah yang teruk mungkin timbul sekiranya keadaan ini berterusan kerana semua sumber tenaga tidak boleh diperbaharui seperti gas asli, minyak dan arang batu akan habis. Menurut perhitungan dan ramalan, tenaga tidak boleh diperbaharui akan habis dalam 50 tahun akan datang.",
        paragraph5:
          "Setelah mencapai akhir hayatnya, minyak masak terpakai adalah sumber yang sering digunakan. Setelah minyak digunakan untuk memasak, ia sering dibuang dan bukannya diguna semula. Individu dan restoran harus menetapkan rancangan kitar semula minyak yang lestari dan berkesan yang sebenarnya akan membawa kepada pengurangan risiko kesihatan, perlindungan alam sekitar melalui tenaga yang boleh diperbaharui dan sisa buangan, kos operasi yang mengurangkan kesan atau aliran masuk pendapatan.",
        paragraph6:
          "Ternyata, anda boleh mengitar semula minyak masak terpakai anda mulai hari ini dengan Arus Oil. Minyak masak terpakai anda akan disaring dan dikisar untuk memastikan ia memenuhi spesifikasi bio-diesel untuk kegunaan industri. (Cth. Automobile)",
      },
      clients: {
        paragraph1: "Bekerjasama rapat dengan rakan kongsi",
        paragraph2:
          "Sejak diperbadankan, kami beruntung dapat bekerjasama dengan organisasi & individu yang komited terhadap kelestarian persekitaran.",
      },
      featured: {
        title: "Seperti yang dilihat pada",
      },
      subscribeAction: {
        title: "Sertai Aplikasi Arus Oil Sekarang !",
        button: "Daftarlah Sekarang",
      },
      createAppointment: {
        title: "Jadualkan temujanji",
        selectInput1: "Hari Pengutipan",
        selectInputPlaceholder1: "Pilih hari penguptipan anda",
        selectInput2: "Masa Pengutipan",
        selectInputPlaceholder2: "Pilih masa penguptipan anda",
        textInput1: "Nombor Telefon",
        textInput2: "Nama Pelanggan",
        addressInput: "Alamat",
        addressPlaceholder: "Masukkan alamat anda",
        uploadInput: "Muat naik imej Minyak Masak yang dikumpul",
        uploadInputBtnText: "Muat naik gambar",
        numberInput1: "Anggaran Berat Minyak Masak (kg)",
        numberInputPlaceholder: "Masukkan anggaran berat minyak masak anda",
        remarks: "Catatan Tambahan",
        remarksPlaceholder: "Masukkan catatan tambahan anda",
        btnText: "Mengemukakan",
      },
      scheduleCondition: {
        phoneError: `Sila masukkan nombor telefon anda dalam format yang betul ${utilPhonePlaceholder()} (Contoh)`,
        nameError: `Please masukkan nama anda`,
        addressError: "Sila pilih alamat anda daripada cadangan yang diberikan",
        preferredTimeError: "Sila pilih masa pilihan anda untuk koleksi",
        preferredDayError: "Sila pilih hari pilihan untuk koleksi",
        estimatedWeightError:
          "Minyak Masak UCO yang dikumpul adalah sekurang-kurangnya 5kg",
        noImageError: "Sila muat naik fail imej",
        ImageTooLargeError: "Saiz fail tidak boleh melebihi 5MB",
      },
    },
    ch: {
      header: {
        signUp: "注册",
        signIn: "登入",
        existingCustomer: "已有账户?",
        dashboard: "活动面板",
      },
      language: ["英文", "马来文", "中文"],
      overlay: {
        text: "我们正在验证您的帐户，请稍候",
      },
      home: {
        ecf:
          "我们需要您的投票，通过提高回收 ♲ 意识来帮助我们清洁吉隆坡市中心的排水渠。现在为我们投票：",
        heroTitle: "我们的平台能让您轻松回收用过的食用油",
        heroDescription:
          "让我们的应用程序来销售处理您用过的食用油。您可以每天查看用过的食用油的售价，联系物流取货，查看支付给您的款项，一切尽在 Arus Oil 应用程序中",
      },
      signUp: {
        text: "马上注册",
      },
      leftContentCard: {
        title: "亲临您家门口回收食用油",
        content:
          "Arus Oil 团队提供上门收集服务来收集您用过的食用油。首先您得注册一个帐户并选择收集日期、时间和地址。",
        button: "即刻安排",
      },
      mpobLicense: {
        title: "持牌经营者 – Arus Oil",
        desc:
          "收集/购买用过的食用油需要 MPOB 许可证。根据 2005 年马来西亚棕榈油委员会许可条例，用过的食用油（基于棕榈油）被归类为污泥棕榈油 (SPO)。​\n\nArus Oil 是 MPOB 的许可运营商，我们的许可编号为 619709017000。使用 MPOB 许可运营可确保所有收集的用过的食用油都被转化为生物柴油，而不是用于任何会危害人类和环境的非法用途。",
      },
      ngoInitiative: {
        ngoTopTitle: {
          heroTitleBlack: "通过回收 UCO 为社区做贡献",
          description:
            "通过将回收用过的食用油的现金返还捐赠给当地的社会企业或非营利组织，让您的小善举产生重大影响。 \n\n 除了回收用过的食用油将其转化为生物柴油，为工业车辆和机器提供动力外，您还可以将支付给您的现金捐赠给非政府组织，以同时解决社会和环境问题。",
          ngoGetStarted: "开始使用",
          ngoTitle2: "为什么我们允许捐款？",
          ngoDescription2:
            "马来西亚人可分为三个不同的收入群体，B40、M40 和 T20。虽然 B40 团体经常回收用过的食用油以获得一定的现金返还作为支持他们生活的补充收入，但我们为 M40 和 T20 团体提供选择，他们选择将回收中获得的现金返还捐赠给慈善活动。 \n\n 通过捐赠额外的现金返还，它有助于提高马来西亚人解决社会问题的意识，同时，您可以享受向任何政府批准的慈善组织捐款的免税待遇。",
        },
        ngoFooter: {
          ngoTitle1: "我们合作的组织",
          ngoCaption1:
            "我们与当地非政府组织和慈善组织密切合作，通过解决社会问题为社区做出贡献。在 Arus Oil 手机程序的 Donees/Donation 页面加入我们，查看更多慈善组织。",
          ghcaTitle: "Great Heart Charity Association",
          ghcaType: "透析患者、老人、孤儿、残疾人、原住民",
          ghcaDescription:
            "Great Heart Charity Association 是马来西亚的一个非盈利慈善组织。他们的目标是向有需要的人提供实质性和有意义的援助。他们的核心价值是让慈善成为每个人生活的一部分。",
          mrcsTitle: "Malaysian Red Crescent Society",
          mrcsType: "弱势群体 - 无证、无家可归者、移民",
          mrcsDescription:
            "马来西亚红新月会是一个致力于人道主义行动和服务的非营利组织。",
          wawTitle: "What A Waste",
          wawType: "B40 群体",
          wawDescription:
            "What A Waste (WaW) 平台旨在减少每天仅因为尚未售出而在食品店丢弃的优质食品的数量。",
          yckTitle: "Yayasan Chow Kit",
          yckType: "吉隆坡青少年",
          yckDescription:
            "Yayasan Chow Kit 是一个 24 小时危机和临时中心，为 Chow Kit 的高危儿童提供膳食、活动、治疗、病例管理和教育计划。",
        },
      },
      carbonEmission: {
        carbonTopTitle: {
          carbonTitleBlack: "收到有关碳排放量节省的报告",
          carbonDescription:
            "碳排放是车辆燃料消耗排放的温室气体 (GHG)。通过回收用过的食用油 (UCO)，您可以在 Arus Oil 应用程序中查看您节省了多少碳排放。回收的 UCO 可以转化为比传统柴油更环保的生物柴油，并有助于减少高达 80% 的碳排放到大气中。",
          carbonGetStarted: "快来了",
          carbonTitle2: "Arus Oil - 遵守 ISCC",
          carbonDescription2:
            "有趣的事实，您知道我们遵守 ISCC（国际可持续发展和碳认证）吗？ ISCC 是一个国际证书系统，旨在纳入可持续性标准并倡导可持续供应链。 \n\n 这对于确定生物柴油转化的有效性至关重要，方法是考虑从用过的食用油原产地（也就是您的家门口）收集的距离。",
        },
        carbonContent: {
          carbonTitle3: "我们计算所节省的碳排放量的方法",
          carbonDescription3:
            "我们通过报告您回收了多少用过的食用油以及我们的物流团队到您所在地的距离来为您节省的碳排放提供透明度和问责制。",
          carbonHeader1: "行驶距离",
          carbonCaption1:
            "我们的 Arus Oil 仪表板将记录在收集回收的 UCO 过程中所行驶的距离，并根据收集的燃料消耗量计算碳排放量。 ",
          carbonHeader2: "根据重量节省的碳排放量",
          carbonCaption2:
            "我们对门到门收集执行最低重量为 5 公斤的 UCO 政策，以确保碳排放总量。此外，我们的仪表板算法优化了我们附近所有时间表的集合，以确保燃油效率",
          carbonButtonText: "查看常见问题",
        },
      },
      jmbInitiative: {
        jmbTopTitle: {
          jmbTitleBlack: "与联合管理机构一起回收 UCO 运动",
          jmbDescription:
            " 我们与联合管理机构 (JMB) 或管理委员会 (MC) 合作，通过宣传活动提高居民对回收用过的食用油的认识。 \n\n 通过与我们合作促进回收工作，水槽或管道堵塞的现象可能会减少，因为居民会充分了解如何处理用过的食用油的正确方法。",
          jmbGetStarted: "获取我们的免费手册",
          jmbTitle2: "为什么要鼓励回收？",
          jmbDescription2: `你知道每年大约有 1.2 亿公吨的食用油被扔到马来西亚吗？污水中未经处理的用过的食用油 (UCO) 经常吸引老鼠和蟑螂等害虫，最终导致当地社区感染疾病。 \n\n 除了污染我们的水和损害我们的水生食物链外，大量处理 UCO 经常会堵塞生活污水中的排水系统并导致水停滞，并最终在马来西亚的季风期间引发洪水。 \n\n 使用 Arus Oil，您可以通过回收使用过的食用油并将其转化为生物柴油，广泛用作工业卡车和货车的替代燃料，从而在拯救和促进健康的生活环境方面发挥作用。 `,
        },
        jmbContent: {
          jmbTitle3: "我们将为您提供什么",
          jmbDescription3:
            "我们有经验帮助您在雪兰莪、槟城和马来西亚其他州的不同社区启动成功使用的食用油回收计划。 ",
          jmbHeader1: "市场营销材料",
          jmbCaption1:
            "我们的团队将为您的宣传活动提供营销幻灯片和媒体，并为您的居民举办关于回收使用过的食用油的介绍工作坊。",
          jmbHeader2: "管理员网页",
          jmbCaption2:
            "我们将生成一个促销代码来鼓励您的社区回收使用过的食用油，您可以在我们专门为您创建的管理门户中查看他们的参与情况。",
        },
        jmbGetInTouch: {
          jmbTitle4: "联络我们",
          jmbDesc: "如需 JMB 合作伙伴关系和查询，请通过所列联系人联系我们",
          jmbSmallHeader1: "公司",
        },
      },
      referralFaqs: {
        title: "推荐计划",
        faqs: {
          faqlist: [
            {
              question: "什么是 Arus Oil 推荐计划？",
              answer:
                "• Arus Oil 推荐计划可让您和您的朋友在 3 个月内享受 10% 的使用过的食用油的购买价格。 \n\n" +
                "• 对于您在 Arus Oil 推荐和回收的每个朋友至少一次，您都有权在 3 个月内将您使用过的食用油的购买价格提高 10%。 \n\n",
            },
            {
              question: "推荐朋友有限制吗？",
              answer:
                "• 您可以推荐尽可能多的朋友！我们对您可以推荐的朋友数量没有限制。",
            },
            {
              question: "是否需要推荐朋友？",
              answer:
                "• 您的朋友不必拥有现有帐户。\n\n" +
                "• 您的朋友必须通过推荐链接注册。\n\n" +
                "• 好友注册后，好友至少回收5公斤用过的食用油一次，双方可享受10%的进价加价。",
            },
            {
              question: "如何推荐朋友？",
              answer:
                "• 登录您的 Arus Oil 帐户。\n\n" +
                "• 点击页面顶部的“推荐朋友”按钮。如果您使用的是移动设备，请单击“汉堡图标”以找到“推荐朋友”按钮。 \n\n" +
                "• 复制您的个人链接，并通过电子邮件、短信或您最喜欢的社交媒体平台将其发送给您的朋友。\n\n" +
                "• 当您邀请您的朋友并且您的朋友至少回收过一次用过的食用油时，你们都有资格参加推荐计划。 \n\n" +
                "• 您可以通过查看“个人资料”部分中的“您的推荐历史”来跟踪您朋友的进度。 \n\n",
            },
            {
              question: "我有一些改进当前石油推荐计划的建议。",
              answer: "• 请随时通过 info@arusoil.com 告诉我们您的反馈",
            },
          ],
        },
      },
      faqs: {
        title: "常见问题",
        faqsList: [
          {
            question: "你收集什么类型的油？",
            answer: "我们只严格收集用过的食用油。我们不收集发动机或黑油",
          },
          {
            question: "什么是食用油？",
            answer:
              "使用过的食用油，通常简称为 UCO，是用于烹饪或油炸的油和脂肪。 UCOS可以是植物油的形式，如玉米油、菜籽油、橄榄油、棕榈油等。剩余或用过的食用油不宜多次使用。",
          },
          {
            question: "如何开始回收用过的食用油？",
            answer:
              "用筛子过滤用过的食用油，确保用过的食用油中没有水或沉淀物的混合物。将用过的食用油放入合适的封闭塑料或金属容器中。",
          },
          {
            question: "你们提供用过的食用油储存容器吗？",
            answer:
              "是的。储存容器根据家庭或餐厅产生的食用油量提供。请致电 +6013-7311007 联系我们以更好地了解这一点。",
          },
          {
            question: "您在哪里，您服务于哪些区域？",
            answer:
              "我们目前为雪兰莪、森美兰、槟城和 W.P.吉隆坡。不要犹豫，注册，因为我们将寻求在每个州都有存在。",
          },
          {
            question: "这项服务是免费的吗？",
            answer:
              "是的，收款服务完全免费。事实上，用过的食用油是向您购买的！",
          },
          {
            question: "油多久收集一次？",
            answer: "根据要求在 3-5 个工作日内收集，最小收集重量为 5 公斤。",
          },
          {
            question: "从我的商店或家中取回用过的食用油后会怎样？",
            answer:
              "用过的食用油将经过过滤和实验室测试，然后再转化为生物柴油。",
          },
          {
            question: "我用过的食用油能拿到多少钱？",
            answer: "注册后，您可以在仪表板上查看购买率。",
          },
          {
            question: "Arus Oil 是经过认证的 ISCC 收集器吗？",
            answer:
              "作为依赖收集器，我们遵守 ISCC 方法。如需更多信息，请访问我们的碳减排网页。",
          },
        ],
      },
      pricing: {
        paragraph1: "定价透明度",
        paragraph2:
          "您使用过的食用油 (UCO) 的销售率可能会根据供需情况而波动。注册 Arus Oil 以获取最新的销售价格",
        subtitle1: "基于体积",
        subtitle2: "当您大量出售用过的食用油 (UCO) 时，可享受更高的销售率",
        subtitle3: "优惠",
        content:
          "创建一个帐户并获得最新可用促销的通知，以便获得更好的用过食用油 (UCO) 销售率",
        subtitle4: "收取站",
        description:
          "上门收取的食用油价格会与收集站收取的食用油价格有所不同。请参考图表上的数据为准",
        button: "查看 FAQs",
      },
      recycleUCO: {
        heroTitle: "食用油再循环",
        heroDescription:
          "自 2017 年以来，Arus Oil 开始通过安装放置在雪兰莪梳邦再也 SS14 公园内的油收集桶来收集用过的食用油。附近的居民将前往公园将他们用过的食用油倒入垃圾箱。 \n\n 从 2021 年开始，Arus Oil 将利用云系统将我们的石油收集计划数字化。只需轻轻一点，Arus Oil 团队就会在您家门口收集您用过的食用油。请参阅下面的进一步说明。",
        leftContentCard1: {
          title: "第一步 - 收集用过的食用油​",
          description:
            "收集用过的食用油可能听起来很复杂，但只要您拥有合适的烹饪设备，就很容易了。完成油炸或烹饪食材后，在过滤器的帮助下将用过的食用油倒入烧杯或瓶子中。 \n\n 使用滤锅的目的是过滤掉食物垃圾并启动回收过程。",
        },
        rightContentCard1: {
          title: "第二步 - 保存用过的食用油",
          description:
            "您可以将收集到的用过的食用油储存在玻璃瓶或塑料罐中。确保将瓶子存放在室温下不易燃的地方。 \n\n​对于含有大量用过的食用油的餐厅，我们建议您用桶储存用过的食用油。如果需要其他支持，请通过电子邮件联系我们。",
        },
        leftContentCard2: {
          title: "第三步 - 注册帐户",
          description:
            "在注册页面注册账户，我们会收集您的信息以便更好地了解您。 Arus Oil 认真对待所有收集到的数据的隐私。 ​\n\n 注册时将向您的邮箱发送一封确认邮件以供确认。",
        },
        rightContentCard2: {
          title: "第四步 - 安排约会​",
          description:
            "在仪表板页面上预约上门收集用过的食用油。输入您喜欢的收货日期和时间，以便为我们的物流团队安排。 \n\n 如有任何其他问题，请访问我们的常见问题解答页面。",
        },
      },
      privacypolicy: {
        termsandcondition: {
          title1: `Arus Oil - 隐私政策`,
          paragraph1: `My Protech Sdn Bhd (1307580-A) (“My Protech”) 保证我们将采取预防措施以确保您的隐私和个人数据受到保护。`,
          paragraph2: `本政策详细说明了我们如何管理您的个人信息以及如果您有任何疑问如何联系我们。`,
          definition: {
            title: `含义`,
            paragraph1: `本隐私政策中未定义的术语与我们的定义相同 `,
            reference1: `服务条款`,
            terms: `("条款")`,
          },
          title2: `我们收集的个人信息`,
          title3: `在 Arus Oil App 上创建帐户所需的信息 `,
          informationCreation: {
            desc:
              "当您在 Arus Oil 应用程序（“应用程序”）上创建帐户时，我们会收集您的个人信息。没有它，我们可能无法为您提供所要求的所有服务。信息包括：",
            bold1: `联系方式、账户、个人信息。 `,
            point1:
              "例如您的名字、姓氏、电话号码、邮寄地址、电子邮件地址、出生日期和职业。 ",
            bold2: `身份验证和付款信息。 `,
            point2: "例如您的身份证号码或其他验证、银行账户或支付账户信息。 ",
            bold3: `额外的个人信息。 `,
            point3:
              "例如性别、首选语言、城市和个人描述。您的帐户设置中显示的其中一些信息是您的公开个人资料页面的一部分，并且将公开可见。",
            bold4: `其他信息。 `,
            point4:
              "例如，当您填写表格、向您的帐户添加信息、回复调查、回复社区论坛、参与促销活动、与我们的客户服务团队和其他用户交流，或与我们分享您的体验时。这可能包括健康或旅行信息。",
          },
          title4: `预订集合时强制收集的信息`,
          informationMandatorily: {
            desc: "当您预订取件时，我们会自动收集个人信息。这些信息可能包括： ",
            bold1: `地理位置信息。 `,
            point1:
              "例如根据您的设备设置从您的 IP 地址或移动设备的 GPS 确定的精确或大致位置。如果您通过设置或设备权限启用此功能，我们也可能在您不使用应用程序时收集此信息。",
            bold2: `使用信息。 `,
            point2:
              "例如您查看的页面或内容、您进行的预订以及在 Arus Oil App 上的其他操作。 ",
            bold3: `记录数据和设备信息。 `,
            point3:
              "例如有关您如何使用该应用程序的详细信息（包括您是否单击了指向第三方应用程序的链接）、IP 地址、访问日期和时间、硬件和软件信息、设备信息、设备事件信息、唯一标识符、崩溃数据、 cookie 数据，以及您在使用应用程序之前或之后查看或参与的页面。即使您尚未创建 Arus Oil 帐户或未登录，我们也可能会收集此信息。 ",
            bold4: `Cookie 和类似技术。 `,
            bold5: `支付交易信息。 `,
            point5:
              "例如使用的支付工具、日期和时间、支付金额、支付工具到期日期、账单邮政编码、电子邮件地址、IBAN 信息、您的地址和其他相关交易详情。",
            bold6: `个人陈述。 `,
            point6: "如健康申报表和财务申报表。 ",
          },
          title5: `从第三方收集的个人信息`,
          informationCollected: {
            desc: `我们从其他来源收集个人信息，例如： `,
            bold1: `第三方服务。 `,
            point1: `
            如果您通过第三方服务（例如 Google、Facebook、Instagram）链接、连接或登录到应用程序，您指示该服务向我们发送信息，例如您的注册、朋友列表和受该服务控制的个人资料信息，或者由您通过该服务的隐私设置授权。`,
            bold2: `背景资料。 `,
            point2: `在适用法律允许的范围内并在需要时征得您的同意，我们可能会获取当地版本的警方、背景或已登记的性犯罪者调查。我们可能会使用您的信息（包括您的全名和出生日期）来获取此类报告。 `,
            bold3: `其他参与组织、团体和机构。 `,
            point3: `如果您被其他组织、组织、团体或机构邀请使用 Arus Oil App，您可能已经提交了有关您自己的个人信息，例如您的电子邮件地址或其他联系信息。 `,
            bold4: `其他来源。`,
            point4: `在适用法律允许的范围内，我们可能会收到有关您的其他信息，例如参考资料、人口统计数据或帮助检测来自第三方服务提供商和/或合作伙伴的欺诈和安全问题的信息，并将其与我们掌握的有关您的信息相结合.例如，我们可能会收到来自身份验证服务提供商的背景调查结果或欺诈警告，用于我们的欺诈预防和风险评估工作。我们可能会从我们的合作伙伴那里收到有关您和您在应用程序内外的活动的信息，或者有关您的体验和互动的信息。我们可能会收到健康信息，包括但不限于与传染病相关的健康信息。`,
          },
          title6: `我们如何使用收集的信息 `,
          provideService: {
            boldDesc: `在 Arus Oil App 上提供、改进和开发服务。 `,
            desc: `我们将个人信息用于： `,
            point1: `使您能够访问应用程序； `,
            point2: `使您能够与其他用户和管理员进行交流； `,
            point3: `执行分析、调试和进行研究； `,
            point4: `在您的所在地提供收集服务； `,
            point5: `为您附近的活动提供协调和组织； `,
            point6: `向您发送消息、更新、安全警报和帐户通知；`,
            point7: `根据您与应用程序的交互、您的搜索和预订历史、您的个人资料信息和偏好以及您提交的其他内容，个性化和定制您的体验。`,
          },
          maintainTrust: {
            boldDesc: `创建和维护一个值得信赖和安全的环境。 `,
            desc: `我们将个人信息用于： `,
            point1: `检测和预防欺诈、垃圾邮件、滥用、安全和安全事件以及其他有害活动； `,
            point2: `研究和打击与我们的非歧视政策一致的歧视； `,
            point3: `进行安全调查和风险评估；`,
            point4: `验证或验证您提供的信息； `,
            point5: `对数据库和其他信息源进行检查，包括背景或警方调查；`,
            point6: `遵守我们的法律义务，保护我们的用户、管理员、管理员的员工和公众的健康和福祉； `,
            point7: `解决与我们用户的纠纷； `,
            point8: `执行我们与第三方的协议； `,
            point9: `遵守法律，响应法律要求，防止伤害并保护我们的权利；`,
            point10: `执行我们的条款和其他政策（例如非歧视政策）；与及 `,
            point11: `对于上述活动，我们可能会根据您与应用程序的交互、您的个人资料信息和您提交的其他内容以及从第三方获得的信息进行分析。在有限的情况下，如果自动化流程检测到我们认为对 My Protech、我们的社区或第三方构成安全或其他风险的活动，自动化流程可能会限制或暂停对应用程序的访问。如果您想对基于自动化流程的决定提出质疑，请通过下方提供的联系信息与我们联系。 `,
          },
          paymentService: {
            boldDesc: ` `,
            desc: `个人信息用于启用或授权第三方使用支付服务： `,
            point1: `检测并防止洗钱、欺诈、滥用和安全事件。 `,
            point2: `进行安全调查和风险评估。 `,
            point3: `遵守法律义务（如反洗钱法规）。 `,
            point4: `执行付款条款和其他付款政策。`,
            point5: `提供和完善支付服务。 `,
          },
          title7: `分享与披露详情`,
          title8: `在您同意或在您的指示下共享`,
          paragraph4: `在您同意的情况下，我们会按照同意时的说明共享您的信息，例如在授权第三方应用程序或网站访问您的帐户时。`,
          paragraph5: `在适用法律允许的情况下，我们可能会使用您的某些信息（例如您的电子邮件地址），对其进行去识别化，并将其与社交媒体应用程序共享，以产生潜在客户、增加应用程序的流量或以其他方式推广我们的产品和服务。`,
          title9: `用户之间共享 `,
          facilitateCollection: {
            desc: `为了帮助促进用户之间的收集或其他交互，我们可能需要共享某些信息，例如： `,
            point1: `当提出取货请求或提交争议时，卖家和买家之间可能会共享某些信息，包括个人资料、姓名、取消历史记录、评论信息、争议结果（如适用）和您的其他信息选择分享和提交。确认预订后，会共享其他信息以协助协调，例如头像和电话号码。 `,
          },
          title10: `遵守法律、响应法律要求、防止伤害和保护我们的权利`,
          complyLaw: {
            paragraph1: `如果法律要求或允许我们这样做，或者在合理需要披露的情况下，我们可能会向法院、执法部门、政府或公共机构、税务机关或授权的第三方披露您的信息：(i)遵守我们的法律义务，(ii) 遵守有效的法律要求或回应针对 My Protech 提出的索赔，(iii) 回应与刑事调查有关的有效法律要求，以解决涉嫌或涉嫌非法活动，或回应或解决可能使我们、您或我们的任何其他用户承担法律或监管责任的任何其他活动，(iv) 执行和管理我们与用户的协议，或 (v) 保护权利、财产或My Protech、其员工、其成员或公众的人身安全。`,
            paragraph2: `在适当的情况下，我们可能会通知用户有关法律请求，除非：(i) 法律程序本身、我们收到的法院命令或适用法律禁止提供通知，或 (ii) 我们认为提供通知是徒劳的、无效的，对个人或团体造成伤害或身体伤害的风险，或对 My Protech、我们的用户造成或增加欺诈或伤害的风险，或使 My Protech 面临妨碍司法公正的索赔。 `,
          },
          title11: `服务供应商 `,
          paragraph6: `我们与关联和非关联服务提供商共享个人信息，以帮助我们运行我们的应用程序，包括帮助我们：(i) 验证您的身份或验证您的身份证明文件，(ii) 对照公共数据库检查信息，(iii) 进行背景调查的服务提供商或警方检查、欺诈预防和风险评估，(iv) 执行产品开发、维护和调试，(v) 允许通过第三方应用程序和软件工具（例如通过与我们的 API 集成）提供 Arus Oil 服务, (vi) 提供客户服务、广告或支付服务，(vii) 处理、处理或评估保险索赔或类似索赔，或 (viii) 促进符合 My Protech 使命的非营利和慈善活动。这些提供商受合同约束以保护您的个人信息并有权访问您的个人信息以执行这些任务。 `,
          title12: `企业关联公司 `,
          corporateAffiliates: {
            desc: `为了支持我们提供、集成、推广和改进应用程序、支付服务和我们的关联公司的服务，我们可能会与我们的公司合作伙伴共享个人信息，这些合作伙伴具有共同的所有权或控制权。一些例子是：`,
            point1: `与支付解决方案提供商共享。为了便于在应用程序上或通过应用程序进行支付，某些信息将与相关支付实体共享。 `,
            point2: `与政府机构和团体共享。如果当局（当地和国际）需要援助，某些信息将与相关方共享，以促进此类活动的执行和规划。在某些情况下，当上述活动将由这些机构资助时，银行详细信息和个人详细信息将被共享。 `,
          },
          title13: `其他重要信息 `,
          title14: `分析您的沟通和体验 `,
          communicateExp: {
            paragraph1: `我们可能会出于本政策“我们如何使用收集的信息”部分所述的原因审查、扫描或分析您在应用程序上的通信和体验，包括欺诈预防、风险评估、监管合规、调查、产品开发、研究、分析，执行我们的`,
            reference1: `服务条款`,
            paragraph2: `, 和客户支持目的。例如，作为我们预防欺诈工作的一部分，我们扫描和分析消息以掩盖联系信息和对其他网站的引用。在某些情况下，我们还可能扫描、查看或分析消息以调试、改进和扩展产品供应。我们在合理可能的情况下使用自动化方法。有时，我们可能需要手动审查通信，例如欺诈调查和客户支持，或评估和改进这些自动化工具的功能。我们不会审查、扫描或分析您的信息通讯以向您发送第三方营销信息，我们也不会出售对这些通讯的评论或分析。 `,
          },
          title15: `关联第三方账户 `,
          thirdPartyAcc: {
            desc: `您可以将您的帐户与某些第三方服务（例如社交网络）相关联。您在这些第三方服务上的联系人被称为“朋友”。当您通过创建此链接来指导数据共享时：`,
            point1: `通过关联账户提供给我们的一些信息可能会发布在您的公开资料中； `,
            point2: `您在应用程序上的活动可能会在应用程序和/或第三方服务上显示给您的朋友； `,
            point3: `您的公开资料中可能包含指向您在该第三方服务上的公开资料的链接； `,
            point4: `其他用户可能会看到您可能与他们有共同之处的任何朋友，或者您是他们朋友的朋友（如果适用）； `,
            point5: `您通过连接您的帐户向我们提供的信息可能会被存储、处理和传输，以用于预防欺诈和风险评估； `,
            point6: `您通过此链接向应用程序提供的信息的发布和显示取决于您在 Arus Oil 应用程序和第三方服务上的设置和授权。 `,
          },
          title16: `第三方合作伙伴和集成 `,
          thirdPartyPartner: {
            paragraph1: `应用程序的某些部分可能链接到不属于 My Protech 的第三方服务，例如 Google 地图/地球/引擎邮件程序。这些服务的使用受这些提供商的隐私政策的约束，例如 `,
            reference1: `谷歌地图/地球附加使用条款`,
            reference2: `和谷歌隐私政策 `,
            paragraph2: `(参考  `,
            reference3: `这里`,
            paragraph3: `有关 Google 如何使用信息的更多信息）。该应用程序不拥有或控制这些第三方，当您与他们互动时，您就是在向他们提供您的信息。 `,
          },
          title17: `管理您的信息 `,
          paragraph19: `您可以通过您的帐户设置访问和更新您的一些个人信息。`,
          title18: `数据访问和可移植性`,
          paragraph20: `在某些司法管辖区，适用法律可能授权您索取您的个人信息的某些副本或关于我们如何处理您的个人信息的信息，索取您以结构化、常用和机器可读格式提供给我们的个人信息的副本，和/或要求我们将此信息传输给另一家服务提供商（在技术上可行的情况下）。`,
          title19: `电子邮件和联系方式 `,
          paragraph21: `我们通常会使用电子邮件或您提供的联系电话与您联系，如果您在帐户偏好中选择了此选项，这可能包括关于提醒、即将发生的事件和更新的不定期通知。`,
          paragraph22: `我们不会将您的电子邮件详细信息或其他联系方式传递给第三方，除非我们认为如上一节所述是适当的。 `,
          paragraph23: `我们鼓励您通过电子邮件向我们发送您的问题或意见，因为我们使用电子邮件作为接收用户反馈的一种方式。 `,
          title20: `社交媒体（Facebook、Instagram、Whatsapp）`,
          paragraph24: `我们的部分广告和促销活动发生在社交媒体上。在这些平台上“喜欢”或“关注”我们使我们能够向我们的用户提供最新信息。这些平台上的用户信息将仅用于促销目的，我们向您保证，这些信息不会传递给任何第三方。 `,
          title21: `安全系统`,
          paragraph25: `虽然没有任何组织可以保证完美的安全性，但我们会不断实施和更新管理、技术和物理安全措施，以帮助保护您的信息免受未经授权的访问、丢失、破坏或更改。 `,
          title22: `本隐私政策的变更`,
          paragraph26: `我们保留根据适用法律随时修改本隐私政策的权利。如果我们这样做，我们将在顶部发布修订后的隐私政策，并在顶部显示更新的“最后更新”日期。如果发生重大变更，我们还将在生效日期前至少三十 (30) 天通过电子邮件向您提供修改通知。如果您不同意修改后的隐私政策，您可以取消您的帐户。如您未在规定时间内注销账户，修改后的隐私政策生效，您继续访问或使用本应用程序将受修改后的隐私政策约束。`,
          title23: `联系我们/或质疑合规性 `,
          contact: {
            paragraph1: `请通过以下详细信息与我们联系，以了解与本政策有关的任何事情，包括问题或意见，或质疑我们对本政策的遵守情况：`,
            companyName: `My Protech Sdn Bhd (1307580-A) `,
            address1: `IOI Rio Office Tower,`,
            address2: `Bandar Puteri Puchong, Selangor, Malaysia `,
            address3: `03-56129935 / info@arusoil.com `,
          },
        },
      },
      referFriend: {
        referralForm: {
          title: "推荐给朋友",
          description:
            "邀请您的朋友与您一起开始回收用过的食用油。确保他们使用您的唯一推荐链接进行注册，这样一旦您的朋友开始回收一次，你们双方的用过食用油的购买价格就会提高 10%。",
        },
        cardSegment: {
          referralSegmentPost: [
            {
              title: "第一步 - 分享您的推荐链接",
              desc:
                "通过分享您的独特推荐链接，邀请您的朋友回收使用过的食用油。",
              img: ShareImage,
            },
            {
              title: "第二步 - 开始回收",
              desc:
                "一旦您的朋友在 Arus Oil 中回收用过的食用油，你们俩将在 3 个月内获得高达 10% 的购买率提升。",
              img: StepCollect,
            },
            {
              title: "第三步 - 邀请更多朋友",
              desc:
                "您可以根据需要推荐和邀请尽可能多的朋友在 Arus Oil 回收使用过的食用油",
              img: RepeatRecycle,
            },
          ],
          title: "如何运作",
        },
        referProgramme: {
          title: "Arus Oil 推荐计划",
          description: "详细了解 Arus Oil 的推荐工作方式",
          viewFaqs: "查看常见问题",
          viewReferral: "查看推荐",
        },
      },
      mission: {
        paragraph1: "全球 75% 的能源使用由化石燃料提供动力",
        paragraph2: "石油将在 2052 - 30 年内耗尽",
        paragraph3: "通过回收确保环境可持续性",
        paragraph4:
          "由于人类消费的增加，我们的全球能源资源正在减少。如果这种情况持续下去，可能会出现严重的问题，因为天然气、石油和煤炭等所有不可再生能源都将耗尽。根据计算和预测，不可再生能源将在未来 50 年内耗尽。",
        paragraph5:
          "使用过的食用油在其使用寿命结束后是一种经常使用的资源。一旦油用于烹饪，通常会被丢弃而不是重复使用。个人和餐馆应该建立一个可持续和有效的石油回收计划，这实际上将导致减少健康风险，通过可再生能源和废物保护环境，减少影响或收入流的运营成本。",
        paragraph6:
          "事实证明，从今天开始，您可以使用 Arus Oil 回收用过的食用油。您用过的食用油将经过过滤和研磨，以确保其符合工业用途的生物柴油规格。 （例如汽车）",
      },
      clients: {
        paragraph1: "与合作伙伴密切合作",
        paragraph2: "自成立以来，我们有幸与致力于环境发展的组织和个人合作。",
      },
      featured: {
        title: "如以下所见",
      },
      subscribeAction: {
        title: "立即下载 Arus Oil 应用程序",
        button: "马上注册",
      },
      footer: {
        legal: "我们的公司",
        vision: "通过回收用过的食用油来确保环境的改善，及可持续性",
        copyright: `© ${moment().format(
          "YYYY"
        )} My Protech Sdn. Bhd.(1307580-A) 版权所有。`,
      },
      createAppointment: {
        title: "申请预约",
        selectInput1: "收货日",
        selectInputPlaceholder1: "选择您喜欢的收货日",
        selectInput2: "收货时间",
        selectInputPlaceholder2: "选着您喜欢的收获时间",
        textInput1: "手机号码",
        textInput2: "收货人名字",
        addressInput: "地址",
        addressPlaceholder: "输入您的地址",
        uploadInput: "上传一张收集食用油的照片",
        uploadInputBtnText: "上传照片",
        numberInput1: "收集食用油的估计重量（公斤）",
        numberInputPlaceholder: "请输入您的估计重量",
        remarks: "其他备注",
        remarksPlaceholder: "输入您的备注",
        btnText: "提交",
      },
      scheduleCondition: {
        phoneError: `请以正确的格式输入您的电话号码${utilPhonePlaceholder()}（示例）`,
        nameError: `请输入您的名字`,
        addressError: "请从提供的建议中选择您的地址",
        preferredTimeError: "请选择可选的收货时间",
        preferredDayError: "请选择可选的收货日",
        estimatedWeightError: "收集的食用油至少为5kg",
        noImageError: "请上传图片文件",
        ImageTooLargeError: "文件大小不得超过5MB",
      },
    },
  }

  if (language === Language.BM) {
    return content.bm
  } else if (language === Language.EN) {
    return content.en
  } else {
    return content.ch
  }
}
